import { Box, Button } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import React from "react";
import { CustomerRow } from "../routes/Customers";
import { UserRow } from "../routes/Users";

import RequestQuote from "@mui/icons-material/RequestQuoteOutlined";

import { AuthRole, useAuth } from "../contexts/AuthContext";

interface LedgerToolbarProps {
  openExpenseReportModal: () => void;
}

function LedgerToolbar(props: LedgerToolbarProps) {
  const auth = useAuth();
  const { openExpenseReportModal } = props;

  return (
    <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
      <Box>
        {auth.decodedToken!.role === AuthRole.ADMIN && (
          <Button
            color="primary"
            startIcon={<RequestQuote />}
            onClick={() => openExpenseReportModal()}
          >
            Gerar Relatório Despesas
          </Button>
        )}
      </Box>
    </GridToolbarContainer>
  );
}

export default LedgerToolbar;
