import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import React from "react";
import CurrencyTextField from "../CurrencyTextField";

export interface CurrencyEditInputCellProps extends GridRenderEditCellParams {
  currency?: string;
}

function CurrencyEditInputCell(props: CurrencyEditInputCellProps) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const parsedValue = React.useMemo(
    () => /\(?R\$\s(\d{1,3}(\.\d{3})*,\d{2})\)?/.exec(value)?.[1] ?? "",
    [value]
  ).replace(".", "");

  // const parsedValue = React.useMemo(() => {
  //   // const regex = /\(?(\d{1,3}(\.\d{3})*,\d{2})\)?/;
  //   const regex = /\(?R\$\s(\d{1,3}(\.\d{3})*,\d{2})\)?/;
  //   const result = regex.exec(value);

  //   if (result?.[1]) {
  //     console.log("regex found input", result[1]);
  //     return result[1];
  //   } else {
  //     console.log("regex not found input", value);
  //     return value;
  //   }

  //   // return /\(?R\$\s(\d{1,3}(\.\d{3})*,\d{2})\)?/.exec(value)?.[1] ?? value
  // }, [value]).replace(".", "");

  // console.log("CurrencyEditInputCell", {
  //   id: `${props.field}-${id}`,
  //   value,
  //   parsedValue,
  // });

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("value change", {
    //   id: `${props.field}-${id}`,
    //   newValue: event.target.value,
    // });
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <CurrencyTextField
      onChange={handleValueChange}
      value={parsedValue}
      currency={props.currency}
    />
  );
}

export default CurrencyEditInputCell;
