import { GridRowId, GridRowModes, GridRowModesModel } from "@mui/x-data-grid";
import * as React from "react";
import dayjs from "dayjs";

import { AuthRole, useAuth } from "./AuthContext";

import { CustomerRow } from "../routes/Customers";
import { UserRow } from "../routes/Users";
import { Alert, AlertProps, Snackbar } from "@mui/material";

type Customer = Omit<CustomerRow, "isNew">;
type User = Omit<UserRow, "isNew">;
// type ReportLanguage = "pt-br" | "en-us";
type Snackbar = Pick<AlertProps, "children" | "severity">;

export enum ReportLanguage {
  EN_US = "en-us",
  PT_BR = "pt-br",
}
export enum TimesheetTaskType {
  ALL = "Todos Tipos",
  ADVISORY = "Consultivo",
  LITIGATION = "Contencioso",
}

export const TimesheetTaskTypes = [
  TimesheetTaskType.ADVISORY,
  TimesheetTaskType.LITIGATION,
];

export const TimesheetGrades = ["Ruim", "Esperado", "Muito Bom"];

interface TimesheetContextProps {
  children: React.ReactNode;
}

interface TimesheetSummary {
  all: string;
  billable: string;
  notBillable: string;
}

export type TimesheetContextType = {
  customers: Customer[];
  employees: User[];
  endDate: dayjs.Dayjs | null;
  formattedStartDate: string | null;
  formattedEndDate: string | null;
  handleBulkEditRows: () => void;
  handleCancelBulkEditRows: () => void;
  handleCancelRow: (id: GridRowId) => () => void;
  handleEditRow: (id: GridRowId) => () => void;
  handleDeleteRow: (id: GridRowId) => () => void;
  handleInsertRow: () => void;
  handleSaveRow: (id: GridRowId) => () => void;
  handleSaveBulkEditedRows: () => void;
  isBulkEditing: boolean;
  isRowEditing: (id?: GridRowId) => boolean;
  page: number;
  pageSize: number;
  rows: TimesheetRow[];
  rowCount: number;
  rowModesModel: GridRowModesModel;
  rowToDelete?: TimesheetRow;
  selectedCustomers: string[];
  selectedCustomersNames: string[];
  selectedEmployees: string[];
  selectedEmployeesNames: string[];
  selectedTaskType: string;
  setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
  setEmployees: React.Dispatch<React.SetStateAction<User[]>>;
  setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  setRows: React.Dispatch<React.SetStateAction<TimesheetRow[]>>;
  setRowToDelete: React.Dispatch<
    React.SetStateAction<TimesheetRow | undefined>
  >;
  setSelectedCustomers: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedEmployees: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedTaskType: React.Dispatch<React.SetStateAction<string>>;
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  startDate: dayjs.Dayjs | null;
  setSnackbar: React.Dispatch<React.SetStateAction<Snackbar | null>>;
  summary?: TimesheetSummary;
};

export const TimesheetContext = React.createContext<TimesheetContextType>(
  null!
);

export interface TimesheetRow {
  id: string;
  date: Date | string;
  durationString: string;
  description: string;
  employee?: User | string;
  customer?: Customer | string;
  isBillable: boolean;
  isNew: boolean;
}

// export enum TimesheetMode {
//   VIEW = 'view',
//   EDITING = 'editing',
//   BULK_EDITING = 'bulk-editing'
// }

const dateFormatter = new Intl.DateTimeFormat("pt-BR", { dateStyle: "short" });

const TimesheetProvider: React.FC<TimesheetContextProps> = ({ children }) => {
  const auth = useAuth();

  /**
   * States
   */
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [employees, setEmployees] = React.useState<User[]>([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [snackbar, setSnackbar] = React.useState<Snackbar | null>(null);

  const [rows, setRows] = React.useState<TimesheetRow[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [summary, setSummary] = React.useState<TimesheetSummary>();
  const [rowToDelete, setRowToDelete] = React.useState<
    TimesheetRow | undefined
  >();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [startDate, setStartDate] = React.useState<dayjs.Dayjs | null>(
    dayjs().startOf("month").startOf("day")
  );
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs | null>(
    dayjs().endOf("month").endOf("day")
  );
  const [selectedCustomers, setSelectedCustomers] = React.useState<string[]>([
    "all",
  ]);
  const [selectedEmployees, setSelectedEmployees] = React.useState<string[]>(
    auth.decodedToken!.role === AuthRole.ADMIN
      ? ["all"]
      : [auth.decodedToken!.sub!]
  );
  const [selectedTaskType, setSelectedTaskType] = React.useState<string>("all");

  const isBulkEditing = React.useMemo(() => {
    return (
      Object.values(rowModesModel).filter(
        (rmm) => rmm.mode === GridRowModes.Edit
      ).length > 1
    );
  }, [rowModesModel]);

  const isRowEditing = React.useCallback(
    (id?: GridRowId) => {
      if (id) {
        return rowModesModel[id]?.mode === GridRowModes.Edit;
      }

      return Object.values(rowModesModel).some(
        (rrm) => rrm.mode === GridRowModes.Edit
      );
    },
    [rowModesModel]
  );

  const alertEditing = React.useCallback(
    (event: BeforeUnloadEvent) => {
      if (isBulkEditing || isRowEditing()) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [isBulkEditing]
  );

  /**
   * Effects
   */
  React.useEffect(() => {
    window.addEventListener("beforeunload", alertEditing);

    // NOTE: uncomment if we need to persist rows into local storage
    // window.addEventListener("unload", () => {});
    return () => {
      window.removeEventListener("beforeunload", alertEditing);
    };
  }, [rowModesModel]);

  // Fetching Rows
  React.useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }

    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/timesheets`);
    url.searchParams.append("startDate", startDate.format("YYYY-MM-DD"));
    url.searchParams.append("endDate", endDate.format("YYYY-MM-DD"));

    if (!selectedCustomers.includes("all")) {
      url.searchParams.append("customerIds", selectedCustomers.join(","));
    }

    if (!selectedEmployees.includes("all")) {
      url.searchParams.append("employeeIds", selectedEmployees.join(","));
    }

    if (selectedTaskType !== "all") {
      url.searchParams.append("taskType", selectedTaskType);
    }

    url.searchParams.append("page", page.toString());
    url.searchParams.append("size", pageSize.toString());

    fetch(url, {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          auth.signOut();
        }

        return response;
      })
      .then((data) => data.json())
      .then((data) => {
        setRowCount(data.pagination.totalCount);
        setRows((oldRows) => {
          const newRows = [
            // ...oldRows,
            ...data.items.map((task: TimesheetRow) => ({
              ...task,
              isNew: false,
            })),
          ];
          console.log({ oldRows, newRows });
          return newRows;
        });
        setSummary(data.summary);
        setRowModesModel((oldModel) => {
          const resetModel = Object.keys(oldModel).reduce(
            (model, id) => ({
              ...model,
              [id]: { mode: GridRowModes.View, ignoreModifications: true },
            }),
            {}
          );
          const newModel = data.items.reduce(
            (model: GridRowModesModel, task: TimesheetRow) => ({
              ...model,
              [task.id]: { mode: GridRowModes.View, ignoreModifications: true },
            }),
            resetModel
          );
          console.log({ oldModel, newModel });
          return newModel;
        });
      })
      .catch((error: Error) => {
        console.warn(error.message);
        setSnackbar({
          children: "Falha ao listar Timesheet",
          severity: "error",
        });
      });
  }, [
    page,
    pageSize,
    startDate,
    endDate,
    selectedEmployees,
    selectedCustomers,
    selectedTaskType,
  ]);

  // Fetching Employees/Users
  React.useEffect(() => {
    // TODO: if auth.decodedToken!.role === "USER" then setEmployees with the user itself only
    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/users`);
    url.searchParams.append("size", "1000");
    url.searchParams.append("deleted", "true");

    fetch(url, {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          auth.signOut();
        }

        return response;
      })
      .then((data) => data.json())
      .then((data) => setEmployees(data.items))
      .catch((error: Error) => {
        console.warn(error.message);
        setSnackbar({
          children: "Falha ao listar usuários",
          severity: "error",
        });
      });
  }, []);

  // Fetching Customers
  React.useEffect(() => {
    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/customers`);
    // url.searchParams.append("page", page.toString());
    url.searchParams.append("size", "1000");

    fetch(url, {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          auth.signOut();
        }

        return response;
      })
      .then((data) => data.json())
      .then((data) => setCustomers(data.items))
      .catch((error: Error) => {
        console.warn(error.message);
        setSnackbar({
          children: "Falha ao listar clientes",
          severity: "error",
        });
      });
  }, []);

  /**
   * Memoized Data
   */
  const formattedStartDate = React.useMemo(
    () => (startDate ? dateFormatter.format(startDate.toDate()) : null),
    [startDate]
  );
  const formattedEndDate = React.useMemo(
    () => (endDate ? dateFormatter.format(endDate.toDate()) : null),
    [endDate]
  );
  const selectedEmployeesNames = React.useMemo(
    () =>
      selectedEmployees
        .filter((s) => s !== "all")
        .reduce((names: string[], employeeId) => {
          const found = employees.find((e) => e.id.toString() === employeeId);

          if (found) {
            names.push(found.name);
          }

          return names;
        }, []),
    [selectedEmployees]
  );

  const selectedCustomersNames = React.useMemo(
    () =>
      selectedCustomers
        .filter((s) => s !== "all")
        .reduce((names: string[], customerId) => {
          const found = customers.find((c) => c.id.toString() === customerId);

          if (found) {
            names.push(found.name);
          }

          return names;
        }, []),
    [selectedCustomers]
  );

  /**
   * Handlers
   */
  const handleBulkEditRows = React.useCallback(() => {
    setRowModesModel(() =>
      rows.reduce(
        (newModel, { id }) => ({
          ...newModel,
          [id]: { mode: GridRowModes.Edit },
        }),
        {}
      )
    );
  }, [rows, rowModesModel]);

  const handleCancelBulkEditRows = React.useCallback(() => {
    const consolidatedRows = rows.filter((r) => +r.id > 0);

    setRows(consolidatedRows);

    setRowModesModel(() =>
      consolidatedRows.reduce(
        (newModel, { id }) => ({
          ...newModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        }),
        {}
      )
    );
  }, [rows, rowModesModel]);

  const handleCancelRow = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow!.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [rows, rowModesModel]
  );

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleEditRow = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleDeleteRow = React.useCallback(
    (id: GridRowId) => () => {
      setRowToDelete(rows.find((row) => row.id === id));
    },
    [rows]
  );

  const handleInsertRow = React.useCallback(() => {
    const insertingRows = rows.filter((row) => +row.id < 0);
    console.log({
      insertingRows,
      id: Math.min(...insertingRows.map((row) => +row.id)),
    });
    const id =
      insertingRows.length > 0
        ? Math.min(...insertingRows.map((row) => +row.id)) - 1
        : -1;

    const newRow: TimesheetRow = {
      id: id.toString(),
      customer:
        selectedCustomers.length === 1 && !selectedCustomers.includes("all")
          ? selectedCustomers[0]
          : "",
      date: "",
      description: "",
      durationString: "",
      employee:
        employees
          .find((e) => e.id === +auth.decodedToken!.sub!)
          ?.id.toString() ?? "",
      isBillable: true,
      isNew: true,
    };

    setRows((oldRows) => [...oldRows, newRow]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "customer" },
    }));
  }, [
    auth.decodedToken,
    employees,
    rows,
    selectedCustomers,
    selectedEmployees,
  ]);

  const handleSaveBulkEditedRows = React.useCallback(() => {
    setRowModesModel(() =>
      rows.reduce(
        (newModel, { id }) => ({
          ...newModel,
          [id]: { mode: GridRowModes.View },
        }),
        {}
      )
    );
  }, [rowModesModel, rows]);

  const handleSaveRow = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const value: TimesheetContextType = {
    customers,
    employees,
    endDate,
    formattedStartDate,
    formattedEndDate,
    handleBulkEditRows,
    handleCancelBulkEditRows,
    handleCancelRow,
    handleEditRow,
    handleDeleteRow,
    handleInsertRow,
    handleSaveBulkEditedRows,
    handleSaveRow,
    isBulkEditing,
    isRowEditing,
    page,
    pageSize,
    rows,
    rowCount,
    rowModesModel,
    rowToDelete,
    selectedCustomers,
    selectedCustomersNames,
    selectedEmployees,
    selectedEmployeesNames,
    selectedTaskType,
    setCustomers,
    setEmployees,
    setEndDate,
    setPage,
    setPageSize,
    setRowModesModel,
    setRows,
    setRowToDelete,
    setSelectedCustomers,
    setSelectedEmployees,
    setSelectedTaskType,
    setSnackbar,
    setStartDate,
    startDate,
    summary,
  };

  return (
    <TimesheetContext.Provider value={value}>
      {children}
      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
    </TimesheetContext.Provider>
  );
};

export function useTimesheet() {
  return React.useContext(TimesheetContext);
}

export default TimesheetProvider;
