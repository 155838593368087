import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import * as R from "remeda";
import styled from "@emotion/styled";

import { LedgerClassificationApiRow } from "../../routes/Finances/LedgerClassificationsPage";
import { LedgerProjectionApiRow } from "../../routes/Finances/LedgerProjectionPage";
import {
  DataGrid,
  GridCellModesModel,
  GridColumnGroup,
  GridColumns,
  GridEnrichedColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Alert,
  AlertProps,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CurrencyEditInputCell from "../DataGridEditCells/CurrencyEditInputCell";
import { useAuth } from "../../contexts/AuthContext";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { LedgerApiRow } from "../../routes/Finances/LedgerPage";
import GridCellExpand from "../GridCellExpand";
import { createProjectionRows, createTotalRow } from "../../utils/projection";
import { useProjection } from "../../contexts/ProjectionContext";

export type LedgerClassification = LedgerClassificationApiRow & {
  totalOnly: boolean;
  flattenedChildren: LedgerClassificationApiRow[];
};

interface LedgerProjectionDataGridProps {
  classificationRoot: LedgerClassification;
  ledgers: LedgerApiRow[];
  projections: LedgerProjectionApiRow[];
}

export type Projection = {
  projectionId: string;
  amount: string;
  currency: string;
};

export type ProjectionRow = {
  id: string;
  isTotal: boolean;
  classificationName: string;
  classificationType: string;
  PrevJaneiro: Projection;
  PrevFevereiro: Projection;
  PrevMarço: Projection;
  PrevAbril: Projection;
  PrevMaio: Projection;
  PrevJunho: Projection;
  PrevJulho: Projection;
  PrevAgosto: Projection;
  PrevSetembro: Projection;
  PrevOutubro: Projection;
  PrevNovembro: Projection;
  PrevDezembro: Projection;
  PrevMean: Projection;
  RealMean: Projection;
  RealTotal: Projection;
};

const DataGridContainer = styled.div`
  flex-grow: 1;
  & .total-row {
    font-weight: bold;
  }

  & .total-row p {
    font-weight: bold;
  }

  & .header-cell {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }
  & .prev-cell {
    border-left: 1px solid #e0e0e0;
  }
  & .real-cell {
    background-color: #f5f5f5;
    border-right: 1px solid #e0e0e0;
  }
`;

type Snackbar = Pick<AlertProps, "children" | "severity">;

const LedgerProjectionDataGrid: FC<LedgerProjectionDataGridProps> = ({
  classificationRoot,
  ledgers,
  projections,
}) => {
  const auth = useAuth();
  const { allRowsByRoot, updateProjectionRow } = useProjection();

  const [snackbar, setSnackbar] = React.useState<Snackbar | null>(null);

  const columnGroups: GridColumnGroup[] = useMemo(
    () => [
      // {
      //   groupId: classificationRoot.name.toUpperCase(),
      //   headerAlign: "center",
      //   children: [{ field: "classificationName" }],
      // },
      ...projections.map<GridColumnGroup>((projection) => ({
        groupId: projection.name,
        headerAlign: "center",
        children: [
          {
            field: `Prev${projection.name.split(" ")[0]}`,
          },
          { field: `Real${projection.name.split(" ")[0]}` },
        ],
      })),
      {
        groupId: "Média Mensal",
        headerAlign: "center",
        children: [{ field: "PrevMean" }, { field: "RealMean" }],
      },
      {
        groupId: "Total Ano",
        headerAlign: "center",
        children: [{ field: "RealTotal" }],
      },
    ],
    [projections]
  );

  // console.log("groups", columnGroups);

  const renderCellExpand = (params: GridRenderCellParams<string>) => {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  };

  const PrevRealBaseColumnConfig: Partial<GridEnrichedColDef<ProjectionRow>> = {
    type: "string",
    headerAlign: "center",
    headerClassName: "header-cell",
    resizable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    align: "center",
    minWidth: 100,
    width: 100,
    // renderCell: renderCellExpand,
    renderHeader: (params) => {
      return (
        <Stack gap={0.5} direction="row" alignItems="center">
          <Typography fontWeight="bold" fontSize=".8125rem">
            {params.colDef.headerName}
          </Typography>
          {params.field.startsWith("Prev") &&
            !params.field.endsWith("Mean") && (
              <Tooltip
                title="Clique 2 vezes na célula para editar o valor."
                placement="top"
                arrow
              >
                <HelpOutlineOutlinedIcon sx={{ fontSize: ".8125rem" }} />
              </Tooltip>
            )}
        </Stack>
      );
    },
    renderCell: (params) => {
      return <Typography fontSize=".8125rem">{params.value}</Typography>;
    },
    valueGetter: (params: GridValueGetterParams<Projection, ProjectionRow>) => {
      const currency = params.value?.currency ?? "BRL";
      const parsedValue = parseFloat(params.value?.amount ?? "0") / 100;

      const formatter = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency,
        signDisplay: "never",
        // minimumFractionDigits: 2,
      });
      const formattedValue = formatter.format(parsedValue);

      if (parsedValue < 0) {
        return `(${formattedValue})`;
      }

      // console.log("value getter", {
      //   id: `${params.field}-${params.row.id}`,
      //   formattedValue,
      //   parsedValue,
      //   value: params.value?.amount,
      // });

      return formattedValue;
      // return formattedValue.split(" ")[1];
    },
  };
  const PrevBaseColumnConfig: Partial<GridEnrichedColDef<ProjectionRow>> = {
    ...PrevRealBaseColumnConfig,
    headerName: "Prev",
    cellClassName: "prev-cell",
    editable: true,
    renderEditCell: (params: GridRenderEditCellParams<Projection>) => {
      // console.log("currency edit cell", params);
      return (
        <CurrencyEditInputCell {...params} currency={params.value?.currency} />
      );
    },
    valueSetter: function (params) {
      const fieldName = this.field as keyof ProjectionRow;
      const newValue = params.row[fieldName];

      const amount =
        parseFloat((params.value as string).replace(",", ".")) * 100;

      (newValue as Projection).amount = isNaN(amount)
        ? (newValue as Projection).amount
        : amount.toString();

      return { ...params.row, [fieldName]: newValue };
    },
  };
  const RealBaseColumnConfig: Partial<GridEnrichedColDef<ProjectionRow>> = {
    ...PrevRealBaseColumnConfig,
    headerName: "Real",
    cellClassName: "real-cell",
    editable: false,
  };

  const columns: GridColumns<ProjectionRow> = [
    {
      type: "string",
      field: "classificationName",
      headerName: "Classificações",
      headerAlign: "center",
      editable: false,
      renderCell: renderCellExpand,
      minWidth: 150,
      // flex: 1,
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevJaneiro",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealJaneiro",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevFevereiro",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealFevereiro",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevMarço",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealMarço",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevAbril",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealAbril",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevMaio",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealMaio",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevJunho",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealJunho",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevJulho",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealJulho",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevAgosto",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealAgosto",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevSetembro",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealSetembro",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevOutubro",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealOutubro",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevNovembro",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealNovembro",
    },
    {
      ...PrevBaseColumnConfig,
      field: "PrevDezembro",
    },
    {
      ...RealBaseColumnConfig,
      field: "RealDezembro",
    },
    {
      ...PrevBaseColumnConfig,
      width: 150,
      editable: false,
      field: "PrevMean",
    },
    {
      ...RealBaseColumnConfig,
      width: 150,
      field: "RealMean",
    },
    {
      ...RealBaseColumnConfig,
      width: 150,
      field: "RealTotal",
    },
  ];

  const [cellEditModesModel, setCellEditModesModel] =
    useState<GridCellModesModel>({});

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel<ProjectionRow>) => {
      const [editedField] = Object.keys(cellEditModesModel[newRow.id]);

      const projection = newRow[
        editedField as keyof ProjectionRow
      ] as Projection;

      const projectionId = projection.projectionId;
      const classificationId = newRow.id;
      let newAmount = projection.amount;

      // const regex = /\(?(\d{1,3}(\.\d{3})*,\d{2})\)?/;
      const regex = /\(?R\$\s(\d{1,3}(\.\d{3})*,\d{2})\)?/;
      const result = regex.exec(newAmount);

      if (result?.[1]) {
        console.log("regex found");
        newAmount = (parseFloat(result?.[1]) * 100).toString();
      } else {
        console.log("regex not found", newAmount);
      }

      const body = JSON.stringify({
        amount: newAmount,
      });

      const getResponseBody = async (response: Response) => {
        if (response.status === 401) {
          auth.signOut();
          throw new Error("Unauthenticated");
        }

        const body = await response.json();

        if (response.status === 400) {
          const codedMessage: string = body.message[0];

          if (
            codedMessage.endsWith("should not be empty") ||
            codedMessage.endsWith("must be a valid enum value")
          ) {
            let field: string;
            const message = "não pode ser vazio";
            if (codedMessage.startsWith("amount")) {
              field = '"Valor"';
            } else {
              field = codedMessage.split(" ")[0];
            }

            throw new Error(`O campo ${field} ${message}`);
          }
        }

        if (![200, 201].includes(response.status)) {
          console.warn(`Failed to insert/modify timesheet: ${response.status}`);
          throw new Error(
            "Alguma coisa inesperada aconteceu, tente novamente mais tarde."
          );
        }

        return body;
      };

      let response: Response;
      try {
        response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/ledger-projection/${projectionId}/classification/${classificationId}`,
          {
            body,
            headers: {
              Authorization: `Bearer ${auth.encodedToken}`,
              "Content-Type": "application/json",
            },
            method: "POST",
          }
        );
      } catch (error) {
        throw new Error(
          "Não foi possível conectar ao servidor, tente novamente mais tarde"
        );
      }

      const responseBody = (await getResponseBody(response)) as {
        amount: string;
      };

      const updatedRow = {
        ...newRow,
        [editedField]: {
          ...projection,
          amount: responseBody.amount,
        },
      };

      setSnackbar({
        children: "Previsão atualizada!",
        severity: "success",
      });

      updateProjectionRow(classificationRoot, updatedRow);

      return updatedRow;
    },
    [auth.encodedToken, cellEditModesModel]
  );

  return (
    <>
      <DataGridContainer>
        <DataGrid
          autoHeight
          rowHeight={25}
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeader": {
              padding: "0px",
            },
            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":
              {
                borderLeft: "1px solid #e0e0e0",
                borderRight: "1px solid #e0e0e0",
                borderBottom: "2px solid #e0e0e0",
              },
          }}
          getRowId={(row) => row.id}
          columnGroupingModel={columnGroups}
          columns={columns}
          rows={allRowsByRoot[classificationRoot.id]}
          cellModesModel={cellEditModesModel}
          onCellModesModelChange={(model) => setCellEditModesModel(model)}
          isCellEditable={(params) => !params.row.isTotal}
          getRowClassName={(params) =>
            params.row.isTotal ? "total-row" : "normal-row"
          }
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) =>
            setSnackbar({
              children: (error as Error).message,
              severity: "error",
            })
          }
          experimentalFeatures={{ columnGrouping: true, newEditingApi: true }}
          hideFooter
        />
      </DataGridContainer>
      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setSnackbar(null)}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={() => setSnackbar(null)} />
      </Snackbar>
    </>
  );
};

export default LedgerProjectionDataGrid;
