import { Box, Button } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import React from "react";
import { CustomerRow } from "../routes/Customers";
import { UserRow } from "../routes/Users";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import RequestQuote from "@mui/icons-material/RequestQuoteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ReportLanguage, useTimesheet } from "../contexts/TimesheetContext";
import { AuthRole, useAuth } from "../contexts/AuthContext";

type Customer = Omit<CustomerRow, "isNew">;
type User = Omit<UserRow, "isNew">;

interface TimesheetToolbarProps {
  customer?: Customer;
  employee?: User;
  openTimesheetReportModal: (language?: ReportLanguage) => void;
  // openExpenseReportModal: () => void;
}

function TimesheetToolbar(props: TimesheetToolbarProps) {
  const auth = useAuth();
  const { openTimesheetReportModal } = props;
  const timesheet = useTimesheet();

  return (
    <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
      <Box>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={timesheet.handleInsertRow}
        >
          Inserir Tarefa
        </Button>
        <Button
          color="primary"
          startIcon={<AccessTimeIcon />}
          onClick={() => openTimesheetReportModal()}
          disabled={timesheet.rows.length === 0}
        >
          Gerar Relatório Timesheet
        </Button>
        {/* {auth.decodedToken!.role === AuthRole.ADMIN && (
          <Button
            color="primary"
            startIcon={<RequestQuote />}
            onClick={() => openExpenseReportModal()}
          >
            Gerar Relatório Despesas
          </Button>
        )} */}
      </Box>
      <Box>
        {!timesheet.isBulkEditing ? (
          <Button
            color="primary"
            startIcon={<EditIcon />}
            onClick={timesheet.handleBulkEditRows}
          >
            Edição em Massa
          </Button>
        ) : (
          <>
            <Button
              color="primary"
              startIcon={<SaveIcon />}
              onClick={timesheet.handleSaveBulkEditedRows}
            >
              Salvar
            </Button>
            <Button
              color="primary"
              startIcon={<CancelIcon />}
              onClick={timesheet.handleCancelBulkEditRows}
            >
              Cancelar
            </Button>
          </>
        )}
      </Box>
    </GridToolbarContainer>
  );
}

export default TimesheetToolbar;
