import React, { useCallback, useEffect, useMemo } from "react";

import styled from "@emotion/styled";

import {
  FileUpload as FileUploadIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

import {
  Alert,
  AlertProps,
  Button,
  CircularProgress,
  Container,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import useBoolean from "../../hooks/useBoolean";
import { CachePolicies, useFetch } from "use-http";
import { AuthRole, useAuth } from "../../contexts/AuthContext";
import { LedgerTransactionApiRow } from "./LedgerTransactionsPage";
import ImportStatementModalStepper from "../../components/Finances/ImportStatementModalStepper";
import LedgerToolbar from "../../components/LedgerToolbar";
import LedgerTransactionsReportModal from "../../components/LedgerTransactionsReportModal";

type Snackbar = Pick<AlertProps, "children" | "severity">;

export type LedgerApiRow = {
  id: string;
  startBalance: string;
  endBalance: string;
  name: string;
  currency: string;
  bankAccount: {
    id: string;
    accountNumber: string;
    bankId: string;
    branch: string;
    financialInstitution: string;
    type: string;
  };
  transactions?: LedgerTransactionApiRow[];
};
export type LedgerApi = {
  count: number;
  paginationOptions: {
    page: number;
    size: number;
  };
  rows: LedgerApiRow[];
};

export default function LedgerPage() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [snackbar, setSnackbar] = React.useState<Snackbar | null>(null);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);

  const [
    isImportStatementOpen,
    { close: closeImportStatement, open: openImportStatement },
  ] = useBoolean();

  const [
    isExpenseReportOpen,
    { close: closeExpenseReport, open: openExpenseReport },
  ] = useBoolean();

  const apiUrl = useMemo(
    () =>
      `${process.env.REACT_APP_API_BASE_URL}/ledger?page=${page}&size=${pageSize}`,
    [page, pageSize]
  );
  const {
    data: ledgers,
    loading,
    get: fetchLedgers,
  } = useFetch<LedgerApi>(
    apiUrl,
    {
      cachePolicy: CachePolicies.NO_CACHE,
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
      method: "get",
    },
    []
  );

  const handleCloseSnackbar = () => {
    setSnackbar(null);
  };

  const handleCloseImportStatement = useCallback((success: boolean) => {
    if (success) {
      setSnackbar({
        children: "Extrato importado com sucesso!",
        severity: "success",
      });
    }
    fetchLedgers();
    closeImportStatement();
  }, []);

  const columns: GridColumns<LedgerApiRow> = [
    {
      field: "name",
      headerName: "Mês/Ano",
      flex: 1,
      editable: false,
    },
    {
      field: "currency",
      headerName: "Moeda",
      flex: 1,
      editable: false,
    },
    {
      field: "bank",
      headerName: "Banco",
      flex: 1,
      editable: false,
      valueGetter: (params) =>
        (params.row.bankAccount as LedgerApiRow["bankAccount"])
          .financialInstitution,
    },
    {
      field: "bankAccount",
      headerName: "Conta",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return (params.value as LedgerApiRow["bankAccount"]).accountNumber;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Ver">
                <VisibilityIcon />
              </Tooltip>
            }
            label="Ver"
            className="textPrimary"
            onClick={() => {
              console.log(`open transactions for ledger "${params.id}"`);
              navigate(`/finances/ledger/${params.id}`);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" gutterBottom>
        Movimentações Financeiras
      </Typography>
      <Stack direction="column" alignItems="flex-end" sx={{ mb: 2 }}>
        <Button
          startIcon={<FileUploadIcon />}
          variant="contained"
          onClick={openImportStatement}
        >
          Importar Extrato
        </Button>
      </Stack>
      <Paper>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" minHeight="60vh">
            <Stack direction="row" gap={1}>
              <CircularProgress size={32} />
              <Typography lineHeight={2}>Carregando...</Typography>
            </Stack>
          </Stack>
        ) : (
          <DataGrid
            autoHeight
            rows={ledgers?.rows ?? []}
            columns={columns}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nenhum extrato existente, realize a importação de um Extrato
                  OFX para prosseguir.
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nenhum extrato encontrada.
                </Stack>
              ),
              Toolbar: LedgerToolbar,
            }}
            componentsProps={{
              toolbar: {
                openExpenseReportModal: openExpenseReport,
              },
            }}
            localeText={{
              // Rows selected footer text
              footerRowSelected: (count) =>
                count !== 1
                  ? `${count.toLocaleString()} linhas selecionadas`
                  : `${count.toLocaleString()} linha selecionada`,
              footerTotalRows: "Total páginas: ",
              footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toString()} de ${totalCount.toLocaleString()} linhas`,
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `Linhas ${from} até ${to} (total: ${count})`,
              },
            }}
            pagination
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            rowCount={ledgers?.count ?? 0}
            onPageChange={setPage}
            rowsPerPageOptions={[100]}
            onPageSizeChange={setPageSize}
          />
        )}
      </Paper>
      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
      {auth.decodedToken!.role === AuthRole.ADMIN && (
        <LedgerTransactionsReportModal
          open={isExpenseReportOpen}
          onClose={closeExpenseReport}
          // customerIds={["all"]}
          // startDate={timesheet.startDate}
          // endDate={timesheet.endDate}
        />
      )}
      {isImportStatementOpen && (
        <ImportStatementModalStepper
          onClose={handleCloseImportStatement}
          open
        />
      )}
    </Container>
  );
}
