import moment from "moment";
import * as React from "react";
import * as R from "remeda";
import { useFetch } from "use-http";

import { useAuth } from "./AuthContext";

import {
  LedgerClassificationApi,
  LedgerClassificationApiRow,
} from "../routes/Finances/LedgerClassificationsPage";
import {
  LedgerClassification,
  ProjectionRow,
} from "../components/LedgerProjectionDataGrid";
import { LedgerApi } from "../routes/Finances/LedgerPage";
import { LedgerProjectionApiRow } from "../routes/Finances/LedgerProjectionPage";
import { createProjectionRows, createTotalRow } from "../utils/projection";

export type ProjectionContextType = {
  allRowsByRoot: Record<string, ProjectionRow[]>;
  classificationsByRoot: LedgerClassification[];
  isClassificationsByRootLoading: boolean;
  ledgers?: LedgerApi;
  isLedgersLoading: boolean;
  years: number[];
  currentYear: number;
  setCurrentYear: React.Dispatch<React.SetStateAction<number>>;
  projections?: LedgerProjectionApiRow[];
  isLedgerProjectionsLoading: boolean;
  updateProjectionRow: (
    classificationRoot: LedgerClassification,
    updatedRow: ProjectionRow
  ) => void;
};

export const ProjectionContext = React.createContext<ProjectionContextType>(
  null!
);

// API fetch hooks

const useLedgerClassificationApi = () => {
  const auth = useAuth();
  const url = `${process.env.REACT_APP_API_BASE_URL}/ledger-classification?flattened=true&include-parent=true`;

  const fetch = useFetch<LedgerClassificationApi>(
    url,
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    },
    []
  );

  const { data: classifications } = fetch;

  return { classifications, ...fetch };
};

const useLedgersApi = () => {
  const auth = useAuth();
  const url = `${process.env.REACT_APP_API_BASE_URL}/ledger?include-transactions=true`;

  const fetch = useFetch<LedgerApi>(
    url,
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    },
    []
  );

  const { data: ledgers } = fetch;

  return { ledgers, ...fetch };
};

const useLedgerProjectionsApi = (year: number) => {
  const auth = useAuth();
  const url = `${process.env.REACT_APP_API_BASE_URL}/ledger-projection?year=${year}`;

  const fetch = useFetch<LedgerProjectionApiRow[]>(
    url,
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    },
    [year]
  );

  return { projections: fetch.data, ...fetch };
};

// API mapping hooks

const useClassificationByRoot = () => {
  const { data: classifications, loading } = useLedgerClassificationApi();

  const { creditRoots, debitRoots } = React.useMemo<{
    creditRoots: LedgerClassification[];
    debitRoots: LedgerClassification[];
  }>(() => {
    const nodes = classifications?.rows ?? [];
    const roots = nodes.filter(
      (classification) => classification.parent === null
    );

    function traverse(traversingNode: LedgerClassificationApiRow) {
      const children = nodes
        .filter((node) => traversingNode.id === node.parent?.id)
        .map((childNode) => traverse(childNode));
      traversingNode.children = children;
      return traversingNode;
    }

    function traverseFlattened(
      traversingNode: LedgerClassificationApiRow,
      root = true
    ): LedgerClassificationApiRow[] {
      const children = nodes
        .filter((node) => traversingNode.id === node.parent?.id)
        .map((childNode) => {
          return traverseFlattened(childNode, false);
        });

      return R.flattenDeep([...(!root ? [traversingNode] : []), children]);
    }

    const mappedRoots = roots?.map<LedgerClassification>((rootNode) => {
      const traversedNode = traverse(rootNode);
      const traversedChildren = traverseFlattened(rootNode);
      const mappedNode: LedgerClassification = {
        ...traversedNode,
        totalOnly: false,
        flattenedChildren: traversedChildren,
      };

      return mappedNode;
    });

    const [creditRoots, debitRoots] = R.partition(
      mappedRoots,
      (root) => root.type === "CREDIT"
    );

    return { creditRoots, debitRoots };
  }, [classifications]);

  const { netIncomeRoot, totalCostsRoot, operativeResultRoot } = React.useMemo<{
    netIncomeRoot: LedgerClassification;
    totalCostsRoot: LedgerClassification;
    operativeResultRoot: LedgerClassification;
  }>(() => {
    const netIncomeRoot: LedgerClassification = {
      id: "net-income",
      name: "Resultado Líquido",
      type: "CREDIT",
      totalOnly: true,
      description: "Resultado líquido do período",
      children: R.flatMap(creditRoots, (root) => root.children ?? []),
      flattenedChildren: R.flatMap(
        creditRoots,
        (root) => root.flattenedChildren ?? []
      ),
    };

    const totalCostsRoot: LedgerClassification = {
      id: "total-costs",
      name: "Custos Totais",
      type: "DEBIT",
      totalOnly: true,
      description: "Custos totais do período",
      children: R.flatMap(debitRoots, (root) => root.children ?? []),
      flattenedChildren: R.flatMap(
        debitRoots,
        (root) => root.flattenedChildren ?? []
      ),
    };

    const operativeResultRoot: LedgerClassification = {
      id: "operative-result",
      name: "Resultado Operacional",
      type: "CREDIT",
      totalOnly: true,
      description: "Resultado operacional do período",
      children: [
        ...(netIncomeRoot.children ?? []),
        ...(totalCostsRoot.children ?? []),
      ],
      flattenedChildren: [
        ...(netIncomeRoot.flattenedChildren ?? []),
        ...(totalCostsRoot.flattenedChildren ?? []),
      ],
    };

    return { netIncomeRoot, totalCostsRoot, operativeResultRoot };
  }, [creditRoots, debitRoots]);

  const classificationsByRoot = React.useMemo<LedgerClassification[]>(
    () => [
      ...R.sortBy(
        creditRoots,
        [(root) => root.type, "asc"],
        [(root) => root.name, "asc"]
      ),
      netIncomeRoot,
      ...R.sortBy(
        debitRoots,
        [(root) => root.type, "asc"],
        [(root) => root.name, "asc"]
      ),
      totalCostsRoot,
      operativeResultRoot,
    ],
    [
      creditRoots,
      debitRoots,
      netIncomeRoot,
      totalCostsRoot,
      operativeResultRoot,
    ]
  );

  return {
    classificationsByRoot,
    loading,
    netIncomeRoot,
    totalCostsRoot,
    operativeResultRoot,
  };
};

const ProjectionProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { classificationsByRoot, loading: isClassificationsByRootLoading } =
    useClassificationByRoot();
  const { ledgers, loading: isLedgersLoading } = useLedgersApi();

  const years = R.range(2022, moment().add(10, "years").year());
  const [currentYear, setCurrentYear] = React.useState<number>(moment().year());

  const { projections, loading: isLedgerProjectionsLoading } =
    useLedgerProjectionsApi(currentYear);

  const [projectionRowsByRoot, setProjectionRowsByRoot] = React.useState<
    Record<string, ProjectionRow[]>
  >({});

  const updateProjectionRow = React.useCallback(
    (classificationRoot: LedgerClassification, updatedRow: ProjectionRow) => {
      const dependentClassifications = classificationsByRoot.filter(
        (c) =>
          c.id !== classificationRoot.id &&
          c.flattenedChildren.find((fc) => fc.id === updatedRow.id)
      );

      setProjectionRowsByRoot((prevRowsByRoot) => {
        const dependentRows = R.fromPairs(
          R.map(dependentClassifications, (c) => [
            c.id,
            prevRowsByRoot[c.id].map((row) =>
              row.id === updatedRow.id ? updatedRow : row
            ),
          ])
        );

        return {
          ...prevRowsByRoot,
          ...dependentRows,
          [classificationRoot.id]: prevRowsByRoot[classificationRoot.id].map(
            (row) => (row.id === updatedRow.id ? updatedRow : row)
          ),
        };
      });
    },
    [classificationsByRoot]
  );

  React.useEffect(() => {
    if (!ledgers) return;
    if (!projections) return;

    const projectionRowsByRoot = R.fromPairs(
      R.map(classificationsByRoot, (root) => [
        root.id,
        createProjectionRows(projections, root, ledgers.rows),
      ])
    );

    setProjectionRowsByRoot(projectionRowsByRoot);
  }, [classificationsByRoot, ledgers?.rows, projections]);

  const projectionTotalRowsbyRoot = React.useMemo(
    () =>
      R.fromPairs(
        R.map(classificationsByRoot, (root) => [
          root.id,
          projectionRowsByRoot[root.id] &&
            createTotalRow(root, projectionRowsByRoot[root.id]),
        ])
      ),
    [classificationsByRoot, projectionRowsByRoot]
  );

  const allRowsByRoot = React.useMemo<Record<string, ProjectionRow[]>>(
    () =>
      R.fromPairs(
        R.map(classificationsByRoot, (root) => [
          root.id,
          [
            ...(!root.totalOnly ? projectionRowsByRoot[root.id] ?? [] : []),
            ...(projectionTotalRowsbyRoot[root.id]
              ? [projectionTotalRowsbyRoot[root.id]]
              : []),
          ],
        ])
      ),
    [projectionRowsByRoot, projectionTotalRowsbyRoot]
  );

  const value: ProjectionContextType = {
    allRowsByRoot,
    classificationsByRoot,
    isClassificationsByRootLoading,
    ledgers,
    isLedgersLoading,
    years,
    currentYear,
    setCurrentYear,
    projections,
    isLedgerProjectionsLoading,
    updateProjectionRow,
  };

  return (
    <ProjectionContext.Provider value={value}>
      {children}
    </ProjectionContext.Provider>
  );
};

export function useProjection() {
  return React.useContext(ProjectionContext);
}

export default ProjectionProvider;
