import React from "react";
import dayjs from "dayjs";
import moment from "moment";

import { TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

export interface DateEditCellParams extends GridRenderEditCellParams {
  startDate?: dayjs.Dayjs | null;
  endDate?: dayjs.Dayjs | null;
}

function DateEditInputCell(props: DateEditCellParams) {
  const { id, value, field, startDate, endDate } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (newValue: unknown) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onChange={handleValueChange}
        disableFuture
        value={value}
        inputFormat="DD/MM/YYYY"
        minDate={startDate}
        maxDate={endDate}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}

export default DateEditInputCell;
