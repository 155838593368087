import React, { useCallback, useMemo, useState } from "react";
import axios from "axios";

import { useAuth } from "../../contexts/AuthContext";
import { useFetch } from "use-http";
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";

type Snackbar = Pick<AlertProps, "children" | "severity">;

export type LedgerBankAccountApiRow = {
  id: string;
  accountNumber: string;
  bankId: string;
  branch: string | null;
  financialInstitution: string;
};

interface LedgerTransactionsReportModalProps extends DialogProps {
  // customerIds: string[];
  // startDate: dayjs.Dayjs | null;
  // endDate: dayjs.Dayjs | null;
}

export type LedgerBankAccountApi = LedgerBankAccountApiRow[];

const useLedgerBankAccountsApi = () => {
  const auth = useAuth();
  const url = `${process.env.REACT_APP_API_BASE_URL}/ledger/bank-account`;

  const fetch = useFetch<LedgerBankAccountApi>(
    url,
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    },
    []
  );

  const { data: bankAccounts } = fetch;

  return { bankAccounts, ...fetch };
};

export type CustomersApiRow = {
  addressCity: string;
  addressComplement: string;
  addressCountry: string;
  addressNumber: string;
  addressStateProvince: string;
  addressStreet: string;
  addressZipcode: string;
  customerType: "Pessoa Jurídica" | "Pessoa Física";
  deletedAt: string | null;
  id: number;
  name: string;
};

export type CustomersApi = {
  items: CustomersApiRow[];
  pagination: {
    page: number;
    size: number;
    totalCount: number;
  };
};

const useCustomersApi = () => {
  const auth = useAuth();
  const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/customers`);

  url.searchParams.append("size", "1000");

  const fetch = useFetch<CustomersApi>(
    url.toString(),
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    },
    []
  );

  const { data } = fetch;

  return { customers: data?.items, ...fetch };
};

const LedgerTransactionsReportModal: React.FC<
  LedgerTransactionsReportModalProps
> = (props) => {
  const auth = useAuth();
  const { bankAccounts } = useLedgerBankAccountsApi();
  const { customers } = useCustomersApi();

  const [snackbar, setSnackbar] = React.useState<Snackbar | null>(null);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    dayjs().startOf("month").startOf("day")
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(
    dayjs().endOf("month").endOf("day")
  );
  const [selectedTransactionTypes, selectTransactionTypes] = useState<string[]>(
    ["all"]
  );
  const [selectedCustomers, selectCustomers] = useState<string[]>(["all"]);
  const [selectedBankAccounts, selectBankAccount] = useState<string[]>(["all"]);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);

  const handleTransactionType = (
    event: SelectChangeEvent<typeof selectedTransactionTypes>
  ) => {
    const { value } = event.target;
    const parsedValue = typeof value === "string" ? value.split(",") : value;

    if (parsedValue.length === 0) {
      selectTransactionTypes(["all"]);
      return;
    }

    const allIndex = parsedValue.indexOf("all");

    if (allIndex === parsedValue.length - 1) {
      selectTransactionTypes([parsedValue[allIndex]]);
    } else if (allIndex === 0) {
      selectTransactionTypes(
        parsedValue.filter((_, index) => index !== allIndex)
      );
    } else {
      selectTransactionTypes(parsedValue);
    }
  };

  const handleCustomerChange = (
    event: SelectChangeEvent<typeof selectedCustomers>
  ) => {
    const { value } = event.target;
    const parsedValue = typeof value === "string" ? value.split(",") : value;

    if (parsedValue.length === 0) {
      selectCustomers(["all"]);
      return;
    }

    const allIndex = parsedValue.indexOf("all");

    if (allIndex === parsedValue.length - 1) {
      selectCustomers([parsedValue[allIndex]]);
    } else if (allIndex === 0) {
      selectCustomers(parsedValue.filter((_, index) => index !== allIndex));
    } else {
      selectCustomers(parsedValue);
    }
  };

  const handleBankAccountChange = (
    event: SelectChangeEvent<typeof selectedBankAccounts>
  ) => {
    const { value } = event.target;
    const parsedValue = typeof value === "string" ? value.split(",") : value;

    if (parsedValue.length === 0) {
      selectBankAccount(["all"]);
      return;
    }

    const allIndex = parsedValue.indexOf("all");

    if (allIndex === parsedValue.length - 1) {
      selectBankAccount([parsedValue[allIndex]]);
    } else if (allIndex === 0) {
      selectBankAccount(parsedValue.filter((_, index) => index !== allIndex));
    } else {
      selectBankAccount(parsedValue);
    }
  };

  const onClose = useCallback(() => {
    selectTransactionTypes(["all"]);
    selectBankAccount(["all"]);
    props.onClose?.({}, "backdropClick");
  }, [props.onClose]);
  // const formattedStartDate = useMemo(
  //   () => startDate?.format("DD/MM/YYYY"),
  //   [startDate]
  // );
  // const formattedEndDate = useMemo(
  //   () => endDate?.format("DD/MM/YYYY"),
  //   [endDate]
  // );

  const generateReport = useCallback(async () => {
    const startDateString = startDate?.format("YYYY-MM-DD");
    const endDateString = endDate?.format("YYYY-MM-DD");

    const url = new URL(
      `${process.env.REACT_APP_API_BASE_URL}/ledger/report/${startDateString}/${endDateString}`
    );

    if (!selectedCustomers.includes("all")) {
      url.searchParams.append("customerIds", selectedCustomers.join(","));
    }

    if (!selectedBankAccounts.includes("all")) {
      url.searchParams.append("bankAccountIds", selectedBankAccounts.join(","));
    }

    if (!selectedTransactionTypes.includes("all")) {
      url.searchParams.append(
        "transactionTypes",
        selectedTransactionTypes.join(",")
      );
    }

    return axios
      .get(url.toString(), {
        headers: { Authorization: `Bearer ${auth.encodedToken!}` },
        responseType: "blob",
      })
      .then((response) => {
        const filename =
          response.headers["content-disposition"]?.split("filename=")[1] ??
          `mov-financeira-${startDateString}_${endDateString}.pdf`;
        saveAs(response.data, filename);
      })
      .catch((error) => {
        console.warn({ error });
        throw error;
      });
  }, [
    auth.encodedToken,
    selectedCustomers,
    selectedBankAccounts,
    selectedTransactionTypes,
    startDate,
    endDate,
  ]);

  const handleGenerateReport = () => {
    setIsReportLoading(true);
    generateReport()
      .then(() => {
        selectBankAccount(["all"]);
        onClose();
      })
      .catch(() => {
        setSnackbar({
          children:
            "Falha ao gerar relatório, recarregue a página e tente novamente",
          severity: "error",
        });
      })
      .finally(() => {
        setIsReportLoading(false);
      });
  };

  return (
    <>
      <Dialog
        {...props}
        aria-labelledby="ledger-transaction-report-dialog-title"
        aria-describedby="ledger-transaction-report-dialog-description"
      >
        <DialogTitle id="ledger-transaction-report-dialog-title">
          Relatório de Despesas
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="ledger-transaction-report-dialog-description">
            {`Você está prestes a gerar um Relatório de Despesas`}
            <br />
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <FormControl sx={{ marginY: 1, width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={setStartDate}
                    label="Data Início"
                    value={startDate}
                    inputFormat="DD/MM/YYYY"
                    maxDate={dayjs().endOf("month").endOf("day")}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <Typography sx={{ m: 2 }}>até</Typography>
              <FormControl sx={{ marginY: 1, width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={setEndDate}
                    label="Data Fim"
                    value={endDate}
                    inputFormat="DD/MM/YYYY"
                    maxDate={dayjs().endOf("month").endOf("day")}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
            <FormControl sx={{ marginY: 1, marginRight: 1, width: "100%" }}>
              <InputLabel id="customer-label">Cliente</InputLabel>
              <Select
                labelId="customer-label"
                id="customer-select"
                multiple
                value={selectedCustomers}
                label="Cliente"
                onChange={handleCustomerChange}
                renderValue={(selected) => {
                  if (selected.includes("all")) return "Todos clientes";
                  const isPlural = selected.length > 1;

                  if (isPlural)
                    return `${selected.length} clientes selecionados`;

                  const customer = customers?.find(
                    (b) => b.id.toString() === selected[0]
                  );

                  if (customer) return `${customer.name} (ID: ${customer.id})`;

                  return `${selected.length} cliente selecionado`;
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                {[
                  <MenuItem key="customer-all" value="all">
                    <Checkbox
                      checked={
                        selectedCustomers.length === 1 &&
                        selectedCustomers[0] === "all"
                      }
                    />
                    <ListItemText primary={`Todos clientes`}>
                      Todos clientes
                    </ListItemText>
                  </MenuItem>,
                  ...(customers ?? []).map((customer) => (
                    <MenuItem
                      key={`customer-${customer.id}`}
                      value={customer.id.toString()}
                    >
                      <Checkbox
                        checked={selectedCustomers.includes(
                          customer.id.toString()
                        )}
                      />
                      <ListItemText primary={customer.name}>
                        {`${customer.name} (ID: ${customer.id})`}
                      </ListItemText>
                    </MenuItem>
                  )),
                ]}
              </Select>
            </FormControl>
            <FormControl sx={{ marginY: 1, marginRight: 1, width: "100%" }}>
              <InputLabel id="transaction-type-label">
                {`Tipo Transação`}
              </InputLabel>
              <Select
                id="transaction-type-multiselect"
                labelId="transaction-type-label"
                multiple
                renderValue={(selected) => {
                  if (selected.includes("all")) return "Todos tipos";

                  return selected
                    .map((s) => {
                      if (s === "CREDIT") return "Crédito";
                      else if (s === "DEBIT") return "Débito";
                      else return s;
                    })
                    .join(", ");
                }}
                input={<OutlinedInput label="Tipo Transação" />}
                value={selectedTransactionTypes}
                onChange={handleTransactionType}
              >
                <MenuItem value="all">
                  <Checkbox
                    checked={
                      selectedTransactionTypes.length === 1 &&
                      selectedTransactionTypes[0] === "all"
                    }
                  />
                  <ListItemText primary="Todos tipos">Todos tipos</ListItemText>
                </MenuItem>
                {[
                  ["Crédito", "CREDIT"],
                  ["Débito", "DEBIT"],
                  // ["INTEREST", "Juros"],
                  // ["DIVIDEND", "Dividendos"],
                  // ["FEE", "Taxas"],
                  // ["SERVICE_CHARGE", "Taxa de Serviço"],
                  // ["DEPOSIT", "Depósito"],
                  // ["ATM", "Caixa Eletrônico"],
                  // ["POINT_OF_SALE", "Máquina de Cartão"],
                  // ["TRANSFER", "Transferência"],
                  // ["CHECK", "Cheque"],
                  // ["PAYMENT", "Pagamento"],
                  // ["CASH", "Saque"],
                  // ["DIRECT_DEPOSIT", "Depósito Direto"],
                  // ["DIRECT_DEBIT", "Débito Direto"],
                  // ["REPEAT_PAYMENT", "Pagamento Recorrente"],
                  // ["HOLD", "Retenção"],
                  // ["OTHER", "Outros"],
                ].map(([text, value]) => (
                  <MenuItem value={value}>
                    <Checkbox
                      checked={selectedTransactionTypes.indexOf(value) > -1}
                    />
                    <ListItemText>{text}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ marginY: 1, marginRight: 1, width: "100%" }}>
              <InputLabel id="bank-account-multiselect-label">
                {`Conta Bancária`}
              </InputLabel>
              <Select
                id="bank-account-multiselect"
                labelId="bank-account-multiselect-label"
                multiple
                renderValue={(selected) => {
                  if (selected.includes("all")) return "Todas contas bancárias";
                  const isPlural = selected.length > 1;

                  if (isPlural) return `${selected.length} contas selecionadas`;

                  const bankAccount = bankAccounts?.find(
                    (b) => b.id === selected[0]
                  );

                  if (bankAccount)
                    return `${bankAccount.financialInstitution} (Conta: ${bankAccount.accountNumber})`;

                  return `${selected.length} conta selecionada`;
                }}
                label="Conta Bancária"
                input={<OutlinedInput label="Conta Bancária" />}
                value={selectedBankAccounts}
                onChange={handleBankAccountChange}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                {[
                  <MenuItem key="bank-all" value="all">
                    <Checkbox
                      checked={
                        selectedBankAccounts.length === 1 &&
                        selectedBankAccounts[0] === "all"
                      }
                    />
                    <ListItemText primary={`Todas contas bancárias`}>
                      {`Todas contas bancárias`}
                    </ListItemText>
                  </MenuItem>,
                  ...[
                    bankAccounts?.map((bankAccount) => (
                      <MenuItem key={bankAccount.id} value={bankAccount.id}>
                        <Checkbox
                          checked={
                            selectedBankAccounts.indexOf(bankAccount.id) > -1
                          }
                        />
                        <ListItemText
                          primary={`${bankAccount.financialInstitution} (Conta: ${bankAccount.accountNumber})`}
                        />
                      </MenuItem>
                    )),
                  ],
                ]}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <LoadingButton
            loading={isReportLoading}
            loadingIndicator="Gerando..."
            startIcon={<DownloadIcon />}
            onClick={handleGenerateReport}
          >
            Gerar Relatório
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setSnackbar(null)}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={() => setSnackbar(null)} />
      </Snackbar>
    </>
  );
};

export default LedgerTransactionsReportModal;
