export function toCurrencyString(amount: string, currency: string = "BRL") {
  const parsedValue = parseFloat(amount) / 100;
  const formatter = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency,
    signDisplay: "never",
  });

  let formattedValue = formatter.format(parsedValue);

  if (parsedValue < 0) {
    formattedValue = `(${formattedValue})`;
  }

  return {
    formattedValue,
    parsedValue,
  };
}
