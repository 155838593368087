import React, { useMemo, useState } from "react";
import * as R from "remeda";
import { useAuth } from "../../contexts/AuthContext";
import {
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { LedgerClassificationApiRow } from "./LedgerClassificationsPage";
import moment from "moment";
import LedgerProjectionAccordion from "../../components/LedgerProjectionAccordion";
import ProjectionProvider, {
  useProjection,
} from "../../contexts/ProjectionContext";

type LedgerProjectionClassificationsRow = {
  id: string;
  amount: string;
  classification: LedgerClassificationApiRow;
};

export type LedgerProjectionApiRow = {
  id: string;
  name: string;
  currency: string;
  projectionClassifications: LedgerProjectionClassificationsRow[];
};

export default function LedgerProjectionPage() {
  const {
    setCurrentYear,
    currentYear,
    classificationsByRoot,
    years,
    isLedgersLoading: loadingLedgers,
    isClassificationsByRootLoading: loadingClassifications,
    isLedgerProjectionsLoading: loadingProjections,
  } = useProjection();

  const classificationAccordions = useMemo(() => {
    return R.map(classificationsByRoot, (root) => (
      <LedgerProjectionAccordion key={root.id} root={root} />
    ));
  }, [classificationsByRoot]);

  return (
    <Stack sx={{ marginX: 4 }}>
      <Stack direction="column" justifyContent="space-between">
        <Typography variant="h5" mb={2}>
          Projeções
        </Typography>
        <Stack direction="row" width={120}>
          <FormControl fullWidth>
            <InputLabel id="projection-year-label">Ano</InputLabel>
            <Select
              defaultValue={currentYear}
              labelId="projection-year-label"
              label="Ano"
              onChange={(e) => setCurrentYear(e.target.value as number)}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack marginTop={2} rowGap={2}>
        {/* TODO: debounce skeleton rendering */}
        {/* {isLoading ? ( */}
        {loadingLedgers || loadingClassifications || loadingProjections ? (
          <>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              animation="wave"
            />
          </>
        ) : (
          <>
            {classificationAccordions.length === 0 && (
              <Typography paddingY={0}>
                Nenhuma classificação encontrada.{" "}
                <Link href="/finances/classifications">
                  Crie uma nova classificação
                </Link>{" "}
                para começar
              </Typography>
            )}
            {classificationAccordions}
          </>
        )}
      </Stack>
    </Stack>
  );
}
