import * as React from "react";
import Container from "@mui/material/Container";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
// import NotificationsIcon from "@mui/icons-material/Notifications";

import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import AppBar from "../AppBar";
import Drawer from "../Drawer";
import { Link as RouterLink, Outlet } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import ListSubheader from '@mui/material/ListSubheader';

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import BusinessIcon from "@mui/icons-material/Business";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LabelIcon from "@mui/icons-material/Label";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
// import ReceiptIcon from "@mui/icons-material/Receipt";

import MyLinkButton from "../components/MyLinkButton";
import { AuthRole, useAuth } from "../contexts/AuthContext";
import { Collapse, Stack, Tooltip } from "@mui/material";

export default function DashboardLayout() {
  const auth = useAuth();
  const [open, setOpen] = React.useState(false);
  const [financesOpen, setFinancesOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleFinancesClick = () => {
    setFinancesOpen(!financesOpen);
  };

  const handleLogout: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    auth.logout();
    // auth.signOut(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            CarneiroSampaio Dashboard
          </Typography>
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <Tooltip
            placement="right"
            title={!open ? "Timesheet" : undefined}
            arrow
          >
            <ListItemButton href="/timesheet" LinkComponent={RouterLink}>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary="Timesheet" />
            </ListItemButton>
          </Tooltip>
          {auth.decodedToken!.role === AuthRole.ADMIN && (
            <Tooltip
              placement="right"
              title={!open ? "Gestão de Usuários" : undefined}
              arrow
            >
              <ListItemButton href="/users" LinkComponent={RouterLink}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Gestão de Usuários" />
              </ListItemButton>
            </Tooltip>
          )}
          {auth.decodedToken!.role === AuthRole.ADMIN && (
            <Tooltip
              placement="right"
              title={!open ? "Gestão de Clientes" : undefined}
              arrow
            >
              <ListItemButton href="/customers" LinkComponent={RouterLink}>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary="Gestão de Clientes" />
              </ListItemButton>
            </Tooltip>
          )}
          <Divider />
          {/* <Typography variant="h6" ml={2}>
            Financeiro
          </Typography> */}
          {/* <ListItemButton LinkComponent={RouterLink} href="/finance/ledger">
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Movimentações Financeiras" />
          </ListItemButton> */}
          {auth.decodedToken!.role === AuthRole.ADMIN && (
            <>
              <Tooltip
                placement="right"
                title={
                  !open
                    ? financesOpen
                      ? "Retrair Financeiro"
                      : "Expandir Financeiro"
                    : undefined
                }
                arrow
              >
                <ListItemButton onClick={handleFinancesClick}>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Financeiro" />
                  {financesOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </Tooltip>
              <Collapse in={financesOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Tooltip
                    placement="right"
                    title={!open ? "Classificações" : undefined}
                    arrow
                  >
                    <ListItemButton
                      LinkComponent={RouterLink}
                      href="/finances/classifications"
                      sx={open ? { pl: 4 } : undefined}
                    >
                      <ListItemIcon>
                        <LabelIcon />
                      </ListItemIcon>
                      <ListItemText primary="Classificações" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip
                    placement="right"
                    title={!open ? "Movimentações Financeiras" : undefined}
                    arrow
                  >
                    <ListItemButton
                      LinkComponent={RouterLink}
                      href="/finances/ledger"
                      sx={open ? { pl: 4 } : undefined}
                    >
                      <ListItemIcon>
                        <SyncAltIcon />
                      </ListItemIcon>
                      <ListItemText primary="Movimentações Financeiras" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip
                    placement="right"
                    title={!open ? "Projeções" : undefined}
                    arrow
                  >
                    <ListItemButton
                      LinkComponent={RouterLink}
                      href="/finances/projections"
                      sx={open ? { pl: 4 } : undefined}
                    >
                      <ListItemIcon>
                        <BarChartIcon />
                      </ListItemIcon>
                      <ListItemText primary="Projeções" />
                    </ListItemButton>
                  </Tooltip>
                </List>
              </Collapse>
            </>
          )}

          <Divider />
          <Tooltip placement="right" title={!open ? "Sair" : undefined} arrow>
            <ListItemButton
              LinkComponent={RouterLink}
              href="/sign-in"
              onClick={handleLogout}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </Tooltip>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Stack sx={{ mt: 4, mb: 4 }}>
          <Outlet />
        </Stack>
      </Box>
    </Box>
  );
}
