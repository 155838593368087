import React, { useCallback, useMemo } from "react";
import * as R from "remeda";
import axios from "axios";
import { saveAs } from "file-saver";

import dayjs from "dayjs";

import styled from "@emotion/styled";

import { AuthRole, useAuth } from "../contexts/AuthContext";

import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridEditDateCell,
  GridEditInputCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowEditStartParams,
  GridRowEditStopParams,
  GridRowModel,
  GridRowModesModel,
  MuiEvent,
  useGridApiContext,
} from "@mui/x-data-grid";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { UserRow } from "./Users";
import { CustomerRow } from "./Customers";
import GridCellExpand from "../components/GridCellExpand";
import appTheme from "../theme";
import { Box } from "@mui/system";
import TimesheetToolbar from "../components/TimesheetToolbar";
import {
  ReportLanguage,
  TimesheetGrades,
  TimesheetRow,
  TimesheetTaskType,
  TimesheetTaskTypes,
  useTimesheet,
} from "../contexts/TimesheetContext";
import { LoadingButton } from "@mui/lab";
import LedgerTransactionsReportModal from "../components/LedgerTransactionsReportModal";
import DownloadIcon from "@mui/icons-material/Download";

const dateFormatter = new Intl.DateTimeFormat("pt-BR", { dateStyle: "short" });

type MyTheme = Readonly<typeof appTheme>;
export type Customer = Omit<CustomerRow, "isNew">;
type User = Omit<UserRow, "isNew">;

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// `;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: (theme as MyTheme).palette.error.main,
    color: (theme as MyTheme).palette.error.contrastText,
  },
}));

// TODO: add mask
// TODO: move to own component
function DurationEditInputCell(props: GridRenderEditCellParams) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} error={!!error} />
    </StyledTooltip>
  );
}

interface DateEditCellParams extends GridRenderEditCellParams {
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
}

// TODO: move to own component
function DateEditInputCell(props: DateEditCellParams) {
  const { id, value, field, startDate, endDate } = props;
  const apiRef = useGridApiContext();
  // const adapter = new AdapterDayjs();

  const dayjsDate = (startDate ? startDate.clone() : dayjs())
    .startOf("month")
    .startOf("date");
  // const selectedMonthYearMoment = moment(`${selectedMonthYear}-02`);
  // console.log({ selectedMonthYearMoment }, dayjs(selectedMonthYearMoment.toDate()).toDate());

  const handleValueChange = (newValue: unknown) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onChange={handleValueChange}
        disableFuture
        value={value}
        inputFormat="DD/MM/YYYY"
        defaultCalendarMonth={dayjsDate}
        minDate={(startDate ? startDate.clone() : dayjs())
          .startOf("month")
          .startOf("date")}
        maxDate={(endDate ? endDate.clone() : dayjs())
          .endOf("month")
          .endOf("date")}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}

function renderDuration(params: GridRenderEditCellParams) {
  return <DurationEditInputCell {...params} />;
}

function renderDate(params: GridRenderEditCellParams) {
  return <GridEditDateCell placeholder="dd/mm/yyyy" {...params} />;
}

function toMonthYearName(date: Date) {
  const formatter = new Intl.DateTimeFormat("pt-br", {
    month: "long",
    year: "numeric",
  });
  const formatted = formatter.format(date);

  return `${formatted.charAt(0).toUpperCase()}${formatted.slice(1)}`;
}

const renderCellExpand = (params: GridRenderCellParams<string>) => {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
};

type MonthYear = {
  name: string;
  value: string;
};

export default function Timesheet() {
  const auth = useAuth();
  const timesheet = useTimesheet();

  const [reportShowEmployee, setReportShowEmployee] = React.useState(true);
  const [reportShowBillable, setReportShowBillable] = React.useState(true);
  const [reportLanguage, setReportLanguage] = React.useState<ReportLanguage>(
    ReportLanguage.PT_BR
  );
  const [reportTaskType, setReportTaskType] = React.useState<TimesheetTaskType>(
    TimesheetTaskType.ALL
  );
  // const [reportCustomerId, setReportCustomerId] = React.useState<string>("");
  const [isTimesheetReportModalOpen, setIsTimesheetReportModalOpen] =
    React.useState(false);
  const [isExpenseReportModalOpen, setIsExpenseReportModalOpen] =
    React.useState(false);
  const [isReportLoading, setIsReportLoading] = React.useState(false);

  const openTimesheetReportModal = (language?: ReportLanguage) => {
    setIsTimesheetReportModalOpen(true);

    if (language) setReportLanguage(language);
  };

  const closeTimesheetReportModal = () => {
    setIsTimesheetReportModalOpen(false);
    setReportLanguage(ReportLanguage.PT_BR);
    setReportTaskType(TimesheetTaskType.ALL);
    // setReportCustomerId("");
  };

  const handleReportGenerate = () => {
    setIsReportLoading(true);
    generateReport(
      timesheet.selectedCustomers,
      reportTaskType,
      reportLanguage
    ).then(() => {
      setIsReportLoading(false);
      setReportShowBillable(true);
      setReportShowEmployee(true);
      closeTimesheetReportModal();
    });
  };

  const generateReport = useCallback(
    (
      customerIds: string[],
      taskType: TimesheetTaskType = TimesheetTaskType.ALL,
      language = ReportLanguage.PT_BR
    ) => {
      const startDateString = timesheet.startDate?.format("YYYY-MM-DD");
      const endDateString = timesheet.endDate?.format("YYYY-MM-DD");

      const url = new URL(
        `${process.env.REACT_APP_API_BASE_URL}/timesheets/report/${startDateString}/${endDateString}`
      );
      const options = [];

      // url.searchParams.set('pdf', 'false');
      url.searchParams.set("language", language);

      if (taskType !== TimesheetTaskType.ALL) {
        url.searchParams.set("taskType", taskType);
      }

      if (!reportShowEmployee) {
        options.push("omit-employees");
      }

      if (!reportShowBillable) {
        options.push("omit-billable");
      }

      if (options.length > 0) {
        url.searchParams.set("options", options.join(","));
      }

      if (!timesheet.selectedEmployees.includes("all")) {
        url.searchParams.append(
          "employeeIds",
          timesheet.selectedEmployees.join(",")
        );
      }

      if (!timesheet.selectedCustomers.includes("all")) {
        url.searchParams.set("customerIds", customerIds.join(","));
      }

      return axios
        .get(url.toString(), {
          headers: {
            Authorization: `Bearer ${auth.encodedToken!}`,
          },
          responseType: "blob",
          // responseType: "text"
        })
        .then((response) => {
          const filename =
            response.headers["content-disposition"]?.split("filename=")[1] ??
            `${startDateString}_${endDateString}-${language}.pdf`;
          saveAs(response.data, filename);
          // const myWindow = window.open('', '_blank');
          // myWindow?.document.write(response.data);
          // myWindow?.focus();
        })
        .catch((error) => {
          console.warn({ error });
          timesheet.setSnackbar({
            children:
              "Falha ao gerar relatório, recarregue a página e tente novamente",
            severity: "error",
          });
        });
    },
    [
      timesheet.selectedCustomers,
      timesheet.selectedEmployees,
      reportShowBillable,
      reportShowEmployee,
      timesheet.startDate,
      timesheet.endDate,
    ]
  );

  const handleRowModesModelChange = (newModel: GridRowModesModel) => {
    timesheet.setRowModesModel(newModel);
  };

  const handleRowEditStart = (
    _params: GridRowEditStartParams,
    event: MuiEvent
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (
    _params: GridRowEditStopParams<TimesheetRow>,
    event: MuiEvent
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleProcessRowUpdateError = (error: Error) => {
    timesheet.setSnackbar({ children: error.message, severity: "error" });
  };

  const handlePageChange = (newPage: number) => {
    timesheet.setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    timesheet.setPageSize(newPageSize);
  };

  const handleRemoveDialogClose = () => {
    timesheet.setRowToDelete(undefined);
  };

  const processRowUpdate = async (
    newRow: GridRowModel<TimesheetRow>,
    oldRow: GridRowModel<TimesheetRow>
  ) => {
    const {
      isNew,
      id,
      customer: newCustomer,
      employee: newEmployee,
      ...newRowData
    } = newRow;

    let customerId =
      typeof newCustomer === "string"
        ? newCustomer
        : newCustomer?.id.toString();
    let employeeId =
      typeof newEmployee === "string"
        ? newEmployee
        : newEmployee?.id.toString();

    let updatedRow: TimesheetRow;

    const getResponseBody = async (response: Response) => {
      if (response.status === 401) {
        auth.signOut();
        throw new Error("Unauthenticated");
      }
      const body = await response.json();

      if (response.status === 400) {
        const codedMessage: string = body.message[0];

        if (
          codedMessage.endsWith("should not be empty") ||
          codedMessage.endsWith("must be a valid enum value")
        ) {
          let field: string;
          const message = "não pode ser vazio";
          if (codedMessage.startsWith("date")) {
            field = '"Data"';
          } else if (codedMessage.startsWith("duration")) {
            field = '"Duração"';
          } else if (codedMessage.startsWith("description")) {
            field = '"Descrição"';
          } else if (codedMessage.startsWith("employeeId")) {
            field = '"Colaborador"';
          } else if (codedMessage.startsWith("customerId")) {
            field = '"Cliente"';
          } else if (codedMessage.startsWith("isBillable")) {
            field = '"Cobrável"';
          } else if (codedMessage.startsWith("taskType")) {
            field = '"Tipo Tarefa"';
          } else {
            field = codedMessage.split(" ")[0];
          }

          throw new Error(`O campo ${field} ${message}`);
        }
      }

      if (![200, 201].includes(response.status)) {
        console.warn(`Failed to insert/modify timesheet: ${response.status}`);
        throw new Error(
          "Alguma coisa inesperada aconteceu, tente novamente mais tarde."
        );
      }

      return body;
    };

    if (isNew) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/timesheets`,
        {
          body: JSON.stringify({
            ...newRowData,
            date:
              typeof newRowData.date === "string"
                ? newRowData.date
                : newRowData.date.toISOString().split("T")[0],
            customer: customerId,
            employee: employeeId,
          }),
          headers: {
            Authorization: `Bearer ${auth.encodedToken!}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );

      const responseBody = await getResponseBody(response);

      updatedRow = { ...responseBody, isNew: false };
      timesheet.setSnackbar({
        children: "Tarefa inserida com sucesso!",
        severity: "success",
      });
    } else {
      const compareEntries = (a: [string, any], b: [string, any]) =>
        a[0] === b[0] && a[1] === b[1];

      const getComparableRowEntries = (row: TimesheetRow) => {
        const { employee, customer, date, ...rowData } = R.omit(row, ["isNew"]);

        const comparableRow: Partial<TimesheetRow> = rowData;

        comparableRow.employee =
          typeof employee === "string" ? employee : employee?.id.toString();

        comparableRow.customer =
          typeof customer === "string" ? customer : customer?.id.toString();

        comparableRow.date =
          typeof date === "string" ? date : date.toISOString().split("T")[0];

        return Object.entries(comparableRow);
      };

      const diff = R.differenceWith(
        getComparableRowEntries(newRow),
        getComparableRowEntries(oldRow),
        compareEntries
      );

      if (diff.length > 0) {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/timesheets/${id}`,
          {
            body: JSON.stringify(R.fromPairs(diff)),
            headers: {
              Authorization: `Bearer ${auth.encodedToken!}`,
              "Content-Type": "application/json",
            },
            method: "PATCH",
          }
        );

        await getResponseBody(response);
        updatedRow = { ...newRow, isNew: false };
        timesheet.setSnackbar({
          children: "Tarefa atualizada com sucesso!",
          severity: "success",
        });
      } else {
        updatedRow = oldRow;
      }
    }

    timesheet.setRows((rows) =>
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );

    return updatedRow;
  };

  const handleRemoveDialogAction = React.useCallback(() => {
    if (!timesheet.rowToDelete) {
      return;
    }

    const url = new URL(
      `${process.env.REACT_APP_API_BASE_URL}/timesheets/${timesheet.rowToDelete.id}`
    );

    fetch(url, {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
      method: "DELETE",
    })
      .then((data) => {
        if (data.status !== 200) {
          throw new Error("Failed Delete");
        }

        timesheet.setRows(
          timesheet.rows.filter((row) => row.id !== timesheet.rowToDelete?.id)
        );
        timesheet.setSnackbar({
          children: "Tarefa removida com sucesso",
          severity: "success",
        });
      })
      .catch((error: Error) => {
        console.warn(error.message);
        timesheet.setSnackbar({
          children: "Falha ao remover tarefa",
          severity: "error",
        });
      })
      .finally(() => {
        handleRemoveDialogClose();
      });
  }, [timesheet.rowToDelete]);

  const columns: GridColumns<TimesheetRow> = React.useMemo(() => {
    const baseColumns: GridColumns<TimesheetRow> = [
      {
        field: "id",
        headerName: "ID",
        width: 80,
        editable: false,
        disableColumnMenu: true,
        valueFormatter: ({ value }) => (+value < 0 ? "-" : value),
      },
      {
        field: "customer",
        type: "singleSelect",
        headerName: "Cliente",
        valueGetter: (params) => {
          let value: Customer | string = params.value;
          if (params.row.isNew) {
            return value;
          }

          if (timesheet.isRowEditing(params.id)) {
            if (typeof value === "string") {
              return value;
            }
            return (value as Customer).id.toString();
          }

          if (typeof value === "string") {
            value = timesheet.customers.find((c) => c.id.toString() === value)!;
          }

          return value.name;
        },
        valueOptions: timesheet.customers.map((c) => ({
          value: c.id.toString(),
          label: `${c.name} (ID: ${c.id})`,
        })),
        editable: true,
        filterable: false,
        disableColumnMenu: true,
        // flex: 1,
        width: 220,
      },
      {
        field: "employee",
        type: "singleSelect",
        headerName: "Colaborador",
        valueGetter: (params) => {
          let value: User | string = params.value;

          if (params.row.isNew) {
            return value;
          }

          if (timesheet.isRowEditing(params.id)) {
            if (typeof value === "string") {
              return value;
            }
            return (value as User).id.toString();
          }

          if (typeof value === "string") {
            value = timesheet.employees.find((e) => e.id.toString() === value)!;
          }

          return value.name;
        },
        valueOptions: timesheet.employees
          .filter((e) =>
            auth.decodedToken!.role === AuthRole.USER
              ? +auth.decodedToken!.sub! === e.id
              : true
          )
          .map((e) => ({
            value: e.id.toString(),
            label: `${e.deletedAt !== null ? "DESATIVADO " : ""}${
              e.name
            } (ID: ${e.id})`,
          })),
        editable: true,
        filterable: false,
        disableColumnMenu: true,
        // flex: 1,
        width: 160,
      },
      {
        // TODO: fix Timezone when date is closer to midnight
        field: "date",
        headerName: "Data",
        type: "date",
        editable: true,
        disableColumnMenu: true,
        renderEditCell: (params: GridRenderEditCellParams) => {
          return (
            <DateEditInputCell
              {...params}
              startDate={timesheet.startDate}
              endDate={timesheet.endDate}
            />
          );
        },
        valueGetter: ({ value, ...params }) => {
          const date = new Date(value);
          return (
            value &&
            new Date(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate(),
              date.getUTCHours(),
              date.getUTCMinutes(),
              date.getUTCSeconds(),
              date.getUTCMilliseconds()
            )
          );
        },
        valueFormatter: ({ value }: { value: Date }) =>
          dateFormatter.format(value),
        minWidth: 180,
      },
      {
        field: "duration",
        headerName: "Duração",
        preProcessEditCellProps: (params) => {
          const hasError = !/^\d{2}:\d{2}$/.test(params.props.value);

          return {
            ...params.props,
            error:
              params.props.value?.length > 0 && hasError
                ? `Formato incorreto, usar HH:MM. Exemplo: 00:30 (0 horas e 30 minutos)`
                : null,
          };
        },
        editable: true,
        disableColumnMenu: true,
        renderEditCell: renderDuration,
      },
      {
        field: "description",
        type: "string",
        headerName: "Descrição",
        flex: 1,
        minWidth: 310,
        editable: true,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Typography whiteSpace="normal" sx={{ wordWrap: "break-word" }}>
              {params.value}
            </Typography>
          );
        },
      },
      // {
      //   field: "observation",
      //   type: "string",
      //   headerName: "Observação",
      //   flex: 1,
      //   minWidth: 310,
      //   editable: true,
      //   disableColumnMenu: true,
      //   renderCell: (params) => {
      //     return (
      //       <Typography whiteSpace="normal" sx={{ wordWrap: "break-word" }}>
      //         {params.value}
      //       </Typography>
      //     );
      //   },
      // },
      // {
      //   field: "grade",
      //   type: "singleSelect",
      //   valueOptions: TimesheetGrades,
      //   headerName: "Nota",
      //   editable: true,
      //   minWidth: 180,
      //   disableColumnMenu: true,
      //   valueSetter: (params) => {
      //     return {
      //       ...params.row,
      //       grade: TimesheetGrades.indexOf(params.value),
      //     };
      //   },
      //   valueGetter: (params) => {
      //     return TimesheetGrades[params.value];
      //   },
      //   valueFormatter: (params) => {
      //     const value: string | number = params.value;

      //     if (typeof value === "string") {
      //       return value;
      //     }

      //     return TimesheetGrades[value] ?? "Sem Nota";
      //   },
      // },
      // auth.decodedToken!.role === AuthRole.ADMIN
      //   ? {
      //       field: "observation",
      //       type: "string",
      //       headerName: "Observação",
      //       flex: 1,
      //       minWidth: 310,
      //       editable: true,
      //       disableColumnMenu: true,
      //       renderCell: (params) => {
      //         return (
      //           <Typography whiteSpace="normal" sx={{ wordWrap: "break-word" }}>
      //             {params.value}
      //           </Typography>
      //         );
      //       },
      //     }
      //   : {},
      // auth.decodedToken!.role === AuthRole.ADMIN
      //   ? ({
      //       field: "grade",
      //       type: "singleSelect",
      //       valueOptions: TimesheetGrades,
      //       headerName: "Nota",
      //       editable: true,
      //       minWidth: 180,
      //       disableColumnMenu: true,
      //       valueSetter: (params) => {
      //         return {
      //           ...params.row,
      //           grade: TimesheetGrades.indexOf(params.value),
      //         };
      //       },
      //       valueGetter: (params) => {
      //         return TimesheetGrades[params.value];
      //       },
      //       valueFormatter: (params) => {
      //         const value: string | number = params.value;

      //         if (typeof value === "string") {
      //           return value;
      //         }

      //         return TimesheetGrades[value] ?? "Sem Nota";
      //       },
      //     })
      //   : ...({}),
      // ...(auth.decodedToken!.role === AuthRole.ADMIN
      //   ? [
      //       {
      //         field: "observation",
      //         type: "string",
      //         headerName: "Observação",
      //         flex: 1,
      //         minWidth: 310,
      //         editable: true,
      //         disableColumnMenu: true,
      //         renderCell: (params) => {
      //           return (
      //             <Typography
      //               whiteSpace="normal"
      //               sx={{ wordWrap: "break-word" }}
      //             >
      //               {params.value}
      //             </Typography>
      //           );
      //         },
      //       },
      //       {
      //         field: "grade",
      //         type: "singleSelect",
      //         valueOptions: TimesheetGrades,
      //         headerName: "Nota",
      //         editable: true,
      //         minWidth: 180,
      //         disableColumnMenu: true,
      //         valueSetter: (params) => {
      //           return {
      //             ...params.row,
      //             grade: TimesheetGrades.indexOf(params.value),
      //           };
      //         },
      //         valueGetter: (params) => {
      //           return TimesheetGrades[params.value];
      //         },
      //         valueFormatter: (params) => {
      //           const value: string | number = params.value;

      //           if (typeof value === "string") {
      //             return value;
      //           }

      //           return TimesheetGrades[value] ?? "Sem Nota";
      //         },
      //       },
      //     ]
      //   : []),
      {
        field: "taskType",
        type: "singleSelect",
        valueOptions: TimesheetTaskTypes,
        headerName: "Tipo Tarefa",
        minWidth: 140,
        editable: true,
        valueFormatter: ({ value }) => value ?? "N/A",
      },
      {
        field: "isBillable",
        type: "boolean",
        headerName: "Cobrável?",
        editable: auth.decodedToken!.role === AuthRole.ADMIN,
        width: 90,
        disableColumnMenu: true,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        width: 80,
        getActions: (params) => {
          if (timesheet.isBulkEditing) {
            return [];
          }

          if (timesheet.isRowEditing(params.id)) {
            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Salvar">
                    <SaveIcon />
                  </Tooltip>
                }
                label="Salvar"
                onClick={timesheet.handleSaveRow(params.id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title="Cancelar">
                    <CancelIcon />
                  </Tooltip>
                }
                label="Cancelar"
                className="textPrimary"
                onClick={timesheet.handleCancelRow(params.id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Editar">
                  <EditIcon />
                </Tooltip>
              }
              label="Editar"
              className="textPrimary"
              onClick={timesheet.handleEditRow(params.id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Deletar">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Deletar"
              onClick={timesheet.handleDeleteRow(params.id)}
              color="inherit"
            />,
          ];
        },
      },
    ];

    if (auth.decodedToken!.role === AuthRole.ADMIN) {
      baseColumns.splice(
        6,
        0,
        {
          field: "observation",
          type: "string",
          headerName: "Observação",
          flex: 1,
          minWidth: 310,
          editable: true,
          disableColumnMenu: true,
          renderCell: (params) => {
            return (
              <Typography whiteSpace="normal" sx={{ wordWrap: "break-word" }}>
                {params.value}
              </Typography>
            );
          },
        },
        {
          field: "grade",
          type: "singleSelect",
          valueOptions: TimesheetGrades,
          headerName: "Nota",
          editable: true,
          minWidth: 180,
          disableColumnMenu: true,
          valueSetter: (params) => {
            return {
              ...params.row,
              grade: TimesheetGrades.indexOf(params.value),
            };
          },
          valueGetter: (params) => {
            return TimesheetGrades[params.value];
          },
          valueFormatter: (params) => {
            const value: string | number = params.value;

            if (typeof value === "string") {
              return value;
            }

            return TimesheetGrades[value] ?? "Sem Nota";
          },
        }
      );
    }

    return baseColumns;
  }, [
    auth,
    timesheet.customers,
    timesheet.employees,
    timesheet.isBulkEditing,
    timesheet.isRowEditing,
    timesheet.handleSaveRow,
    timesheet.handleCancelRow,
    timesheet.handleEditRow,
    timesheet.handleDeleteRow,
  ]);

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    timesheet.setStartDate(date);
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    timesheet.setEndDate(date);

    if (date?.isBefore(timesheet.startDate)) {
      timesheet.setStartDate(date.clone().startOf("month").startOf("day"));
    }
  };

  const handleCustomerChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    const parsedValue = typeof value === "string" ? value.split(",") : value;

    if (parsedValue.length === 0) {
      timesheet.setSelectedCustomers(["all"]);
      return;
    }

    const allIndex = parsedValue.indexOf("all");

    // TODO: prompt user if editing

    if (allIndex === parsedValue.length - 1) {
      timesheet.setSelectedCustomers([parsedValue[allIndex]]);
    } else if (allIndex === 0) {
      timesheet.setSelectedCustomers(
        parsedValue.filter((_, index) => index !== allIndex)
      );
    } else {
      timesheet.setSelectedCustomers(parsedValue);
    }
  };

  const handleEmployeeChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    const parsedValue = typeof value === "string" ? value.split(",") : value;

    if (parsedValue.length === 0) {
      timesheet.setSelectedEmployees(["all"]);
      return;
    }

    const allIndex = parsedValue.indexOf("all");

    // TODO: prompt user if editing

    if (allIndex === parsedValue.length - 1) {
      timesheet.setSelectedEmployees([parsedValue[allIndex]]);
    } else if (allIndex === 0) {
      timesheet.setSelectedEmployees(
        parsedValue.filter((_, index) => index !== allIndex)
      );
    } else {
      timesheet.setSelectedEmployees(parsedValue);
    }
  };

  const handleTaskTypeChange = (event: SelectChangeEvent<string>) => {
    const { value: parsedValue } = event.target;

    timesheet.setSelectedTaskType(parsedValue);
  };

  const handleReportShowEmployee = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReportShowEmployee(event.target.checked);
  };

  const handleReportShowBillable = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReportShowBillable(event.target.checked);
  };

  const handleReportLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportLanguage(event.target.value as ReportLanguage);
  };

  const handleReportTaskTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReportTaskType(event.target.value as TimesheetTaskType);
  };

  return (
    <Stack marginX={4}>
      <Typography variant="h5" gutterBottom>
        Timesheet
      </Typography>
      {/* {timesheet.summary && (
        <Paper sx={{
          mt: 2,
          mb: 2,
          p: 2
        }}>
          <Typography variant="h6" textAlign="center">Resumo de Horas</Typography>
          <Typography sx={{ mt: 2, textAlign: "center" }}>
            <b>Total: {timesheet.summary.all} horas</b>
            <br />
            Cobr&aacute;veis: {timesheet.summary.billable} horas
            <br />
            N&atilde;o-Cobr&aacute;veis: {timesheet.summary.notBillable} horas
          </Typography>
        </Paper>
      )} */}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <FormControl sx={{ marginY: 1, marginRight: 1, width: 250 }}>
            <InputLabel id="employee-label">Colaborador</InputLabel>
            <Select
              labelId="employee-label"
              id="employee-select"
              multiple
              value={timesheet.selectedEmployees}
              label="Colaborador"
              onChange={handleEmployeeChange}
              renderValue={(selected) => {
                if (selected.includes("all")) {
                  return "Todos Colaboradores";
                }

                return selected
                  .map(
                    (s) =>
                      timesheet.employees.find((e) => e.id.toString() === s)
                        ?.name ?? s
                  )
                  .join(", ");
              }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
            >
              {[
                auth.decodedToken!.role === AuthRole.ADMIN ? (
                  <MenuItem key={`employee-all`} value={"all"}>
                    <Checkbox
                      checked={
                        timesheet.selectedEmployees.length === 1 &&
                        timesheet.selectedEmployees[0] === "all"
                      }
                    />
                    <ListItemText primary={"Todos Colaboradores"}>
                      Todos Colaboradores
                    </ListItemText>
                  </MenuItem>
                ) : null,
                ...timesheet.employees.map((employee) => (
                  <MenuItem
                    key={`employee-${employee.id}`}
                    value={employee.id.toString()}
                  >
                    <Checkbox
                      checked={
                        timesheet.selectedEmployees.indexOf(
                          employee.id.toString()
                        ) > -1
                      }
                    />
                    <ListItemText
                      primary={employee.name}
                    >{`${employee.name} (ID: ${employee.id})`}</ListItemText>
                  </MenuItem>
                )),
              ]}
            </Select>
          </FormControl>
          <FormControl sx={{ marginY: 1, marginRight: 1, width: 250 }}>
            <InputLabel id="customer-label">Cliente</InputLabel>
            <Select
              labelId="customer-label"
              id="customer-select"
              multiple
              value={timesheet.selectedCustomers}
              label="Cliente"
              onChange={handleCustomerChange}
              renderValue={(selected) => {
                if (selected.includes("all")) {
                  return "Todos Clientes";
                }

                return selected
                  .map(
                    (s) =>
                      timesheet.customers.find((c) => c.id.toString() === s)
                        ?.name ?? s
                  )
                  .join(", ");
              }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
            >
              {[
                <MenuItem key={`customer-all`} value={`all`}>
                  <Checkbox
                    checked={
                      timesheet.selectedCustomers.length === 1 &&
                      timesheet.selectedCustomers[0] === "all"
                    }
                  />
                  <ListItemText primary={"Todos Clientes"}>
                    Todos Clientes
                  </ListItemText>
                </MenuItem>,
                ...timesheet.customers.map((customer) => (
                  <MenuItem
                    key={`customer-${customer.id}`}
                    value={customer.id.toString()}
                  >
                    <Checkbox
                      checked={
                        timesheet.selectedCustomers.indexOf(
                          customer.id.toString()
                        ) > -1
                      }
                    />
                    <ListItemText primary={customer.name}>
                      {`${customer.name} (ID: ${customer.id})`}
                    </ListItemText>
                  </MenuItem>
                )),
              ]}
            </Select>
          </FormControl>
          <FormControl sx={{ marginY: 1, width: 150 }}>
            <InputLabel id="task-type-label">Tipo Tarefa</InputLabel>
            <Select
              labelId="task-type-label"
              id="task-type-select"
              value={timesheet.selectedTaskType}
              label="Tipo Tarefa"
              onChange={handleTaskTypeChange}
              renderValue={(selected) => {
                if (selected === "all") {
                  return "Todos Tipos";
                }

                return selected;
              }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
            >
              <MenuItem key={`task-type-all`} value={`all`}>
                <Checkbox checked={timesheet.selectedTaskType === "all"} />
                <ListItemText primary="Todos Tipos">Todos Tipos</ListItemText>
              </MenuItem>
              <MenuItem key={`task-type-advisory`} value={`Consultivo`}>
                <Checkbox
                  checked={timesheet.selectedTaskType === "Consultivo"}
                />
                <ListItemText primary="Consultivo">Consultivo</ListItemText>
              </MenuItem>
              <MenuItem key={`task-type-litigation`} value={`Contencioso`}>
                <Checkbox
                  checked={timesheet.selectedTaskType === "Contencioso"}
                />
                <ListItemText primary="Contencioso">Contencioso</ListItemText>
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <FormControl sx={{ marginY: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={handleStartDateChange}
                label="Data Início"
                value={timesheet.startDate}
                inputFormat="DD/MM/YYYY"
                maxDate={dayjs().endOf("month").endOf("day")}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <Typography sx={{ m: 2 }}>até</Typography>
          <FormControl sx={{ marginY: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={handleEndDateChange}
                label="Data Fim"
                value={timesheet.endDate}
                inputFormat="DD/MM/YYYY"
                maxDate={dayjs().endOf("month").endOf("day")}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Stack>
      </Stack>
      {timesheet.summary && (
        <Paper
          sx={{
            mt: 2,
            mb: 2,
            p: 2,
          }}
        >
          <Typography variant="h6" textAlign="center">
            Resumo de Horas
          </Typography>
          <Typography sx={{ mt: 2, textAlign: "center" }}>
            <b>Total: {timesheet.summary.all} horas</b>
            <br />
            Cobr&aacute;veis: {timesheet.summary.billable} horas
            <br />
            N&atilde;o-Cobr&aacute;veis: {timesheet.summary.notBillable} horas
          </Typography>
        </Paper>
      )}
      <Paper>
        <DataGrid
          autoHeight
          getRowHeight={() => "auto"}
          rows={timesheet.rows}
          columns={columns}
          localeText={{
            // Rows selected footer text
            footerRowSelected: (count) =>
              count !== 1
                ? `${count.toLocaleString()} linhas selecionadas`
                : `${count.toLocaleString()} linha selecionada`,
            footerTotalRows: "Total páginas: ",
            footerTotalVisibleRows: (visibleCount, totalCount) =>
              `${visibleCount.toString()} de ${totalCount.toLocaleString()} linhas`,
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `Linhas ${from} até ${to} (total: ${count})`,
            },
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nenhuma Tarefa existente
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nenhuma Tarefa encontrada
              </Stack>
            ),
            Toolbar: TimesheetToolbar,
          }}
          componentsProps={{
            toolbar: {
              openTimesheetReportModal: openTimesheetReportModal,
            },
          }}
          editMode="row"
          rowModesModel={timesheet.rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          page={timesheet.page}
          pageSize={timesheet.pageSize}
          paginationMode="server"
          rowCount={timesheet.rowCount}
          // initialState={{
          //   pagination: {
          //     page: timesheet.page,
          //     pageSize: timesheet.pageSize,
          //   }
          // }}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[]}
          onPageSizeChange={handlePageSizeChange}
          experimentalFeatures={{ newEditingApi: true }}
          // sx={{
          //   "& .MuiDataGrid-root .MuiDataGrid-cell": {
          //     whiteSpace: "normal",
          //     wordWrap: "break-word",
          //   },
          // }}
        />
      </Paper>
      {/* {timesheet.summary && (
        <Paper sx={{
          alignSelf: 'flex-end',
          mt: 2,
          p: 2
        }}>
          <Typography variant="h6">Sumário</Typography>
          <Typography sx={{ mt: 2, textAlign: "right" }}>
            <b>Total: {timesheet.summary.all} horas</b>
            <br />
            Cobr&aacute;veis: {timesheet.summary.billable} horas
            <br />
            N&atilde;o-Cobr&aacute;veis: {timesheet.summary.notBillable} horas
          </Typography>
        </Paper>
      )} */}
      {/* {auth.decodedToken!.role === AuthRole.ADMIN && (
        <LedgerTransactionsReportModal
          open={isExpenseReportModalOpen}
          onClose={closeExpenseReportModal}
          // customerIds={timesheet.selectedCustomers}
          // startDate={timesheet.startDate}
          // endDate={timesheet.endDate}
        />
      )} */}
      <Dialog
        open={isTimesheetReportModalOpen}
        onClose={closeTimesheetReportModal}
        aria-labelledby="report-dialog-title"
        aria-describedby="report-dialog-description"
      >
        <DialogTitle id="report-dialog-title">
          Gerar Relatório de Horas
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="report-dialog-description">
            {`Você está prestes a gerar um Relatório de Horas ${
              timesheet.selectedEmployeesNames.length > 0
                ? `dos colaboradores "${timesheet.selectedEmployeesNames}"`
                : "de todos colaboradores"
            } para ${
              timesheet.selectedCustomersNames.length > 0
                ? `os clientes "${timesheet.selectedCustomersNames}"`
                : "todos clientes"
            } durante o período de "${timesheet.formattedStartDate}" até "${
              timesheet.formattedEndDate
            }"`}
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              // m: "auto",
              width: "fit-content",
            }}
          >
            <FormControl sx={{ mt: 2 }}>
              <FormLabel id="report-language-radio-group-label">
                Linguagem
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="report-language-radio-group-label"
                value={reportLanguage}
                onChange={handleReportLanguage}
                name="report-language-radio-group"
              >
                <FormControlLabel
                  control={<Radio />}
                  value={ReportLanguage.PT_BR}
                  label="Português"
                />
                <FormControlLabel
                  control={<Radio />}
                  value={ReportLanguage.EN_US}
                  label="Inglês"
                />
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <FormLabel id="report-task-type-label">Tipo Tarefa</FormLabel>
              <RadioGroup
                row
                aria-labelledby="report-task-type-label"
                value={reportTaskType}
                onChange={handleReportTaskTypeChange}
                name="report-task-type-radio-group"
              >
                <FormControlLabel
                  control={<Radio />}
                  value={TimesheetTaskType.ALL}
                  label={TimesheetTaskType.ALL}
                />
                <FormControlLabel
                  control={<Radio />}
                  value={TimesheetTaskType.ADVISORY}
                  label={TimesheetTaskType.ADVISORY}
                />
                <FormControlLabel
                  control={<Radio />}
                  value={TimesheetTaskType.LITIGATION}
                  label={TimesheetTaskType.LITIGATION}
                />
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ mt: 2, minWidth: 250, width: 350 }} fullWidth>
              <FormLabel id="report-options-label">{`Opções de Visalização`}</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reportShowBillable}
                    onChange={handleReportShowBillable}
                  />
                }
                label="Mostrar Cobrável/Não-Cobrável"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reportShowEmployee}
                    onChange={handleReportShowEmployee}
                  />
                }
                label="Mostrar Colaborador"
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeTimesheetReportModal()}>Cancelar</Button>
          <LoadingButton
            loading={isReportLoading}
            loadingIndicator="Gerando..."
            startIcon={<DownloadIcon />}
            onClick={() => handleReportGenerate()}
          >
            Gerar Relatório
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {!!timesheet.rowToDelete && (
        <Dialog
          open
          onClose={handleRemoveDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Você tem certeza que deseja remover esta Tarefa?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Você está prestes a remover a Tarefa (ID: ${
                timesheet.rowToDelete.id
              }) realizada no dia "${dateFormatter.format(
                new Date(timesheet.rowToDelete.date)
              )}" por "${
                (timesheet.rowToDelete.employee as User).name
              }" para o cliente "${
                (timesheet.rowToDelete.customer as Customer).name
              }"`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemoveDialogClose} autoFocus>
              Cancelar
            </Button>
            <Button color="error" onClick={handleRemoveDialogAction}>
              Remover
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  );
}
