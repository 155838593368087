import * as R from "remeda";
import {
  LedgerClassification,
  Projection,
  ProjectionRow,
} from "../components/LedgerProjectionDataGrid";
import { LedgerApiRow } from "../routes/Finances/LedgerPage";
import { LedgerProjectionApiRow } from "../routes/Finances/LedgerProjectionPage";

export const createProjectionRows = (
  projections: LedgerProjectionApiRow[],
  classificationRoot: LedgerClassification,
  ledgerRows: LedgerApiRow[]
) =>
  classificationRoot.flattenedChildren.map((classification) => {
    const baseRow: Partial<ProjectionRow> = {
      id: classification.id,
      isTotal: false,
      classificationName: classification.name,
      classificationType: classification.type,
    };

    const projectionMean: [string, Projection] = [
      "PrevMean",
      {
        projectionId: "prevMean",
        amount: (
          R.pipe(
            projections,
            R.flatMap((p) => p.projectionClassifications),
            R.filter((pc) => pc.classification.id === classification.id),
            R.sumBy((pc) => parseFloat(pc.amount))
          ) / 12
        ).toString(),
        currency: "BRL",
      },
    ];

    const transactionMean: [string, Projection] = [
      "RealMean",
      {
        projectionId: "realMean",
        amount: (
          R.pipe(
            ledgerRows,
            // R.filter((ledger) => ledger.name === classification.name),
            R.flatMap((l) => l.transactions),
            R.filter(
              (t) =>
                (typeof t?.secondaryClassification === "string"
                  ? t?.secondaryClassification
                  : t?.secondaryClassification?.id) === classification.id
            ),
            R.sumBy((t) => (t ? parseFloat(t.amount) : 0))
          ) / 12
        ).toString(),
        currency: "BRL",
      },
    ];

    const transactionTotal: [string, Projection] = [
      "RealTotal",
      {
        projectionId: "realTotal",
        amount: R.pipe(
          ledgerRows,
          // R.filter((ledger) => ledger.name === classification.name),
          R.flatMap((l) => l.transactions),
          R.filter(
            (t) =>
              (typeof t?.secondaryClassification === "string"
                ? t?.secondaryClassification
                : t?.secondaryClassification?.id) === classification.id
          ),
          R.sumBy((t) => (t ? parseFloat(t.amount) : 0))
        ).toString(),
        currency: "BRL",
      },
    ];

    const transactionPairs = projections.map<[string, Projection]>(
      (projection) => {
        const key = `Real${projection.name.split(" ")[0]}`;

        const projectionSum = R.pipe(
          ledgerRows,
          R.filter((ledger) => ledger.name === projection.name),
          R.flatMap((ledger) =>
            ledger.transactions?.filter(
              (t) =>
                (typeof t?.secondaryClassification === "string"
                  ? t?.secondaryClassification
                  : t?.secondaryClassification?.id) === classification.id
            )
          ),
          R.sumBy((transaction) =>
            transaction ? parseFloat(transaction.amount) : 0
          )
        );

        return [
          key,
          {
            projectionId: projection.id,
            amount: projectionSum.toString(),
            currency: projection.currency,
          },
        ];
      }
    );

    const projectionPairs = projections.map<[string, Projection]>(
      (projection) => {
        const projectionClassification =
          projection.projectionClassifications.find(
            (projectionClassification) =>
              projectionClassification.classification.id === classification.id
          );
        return [
          `Prev${projection.name.split(" ")[0]}`,
          {
            projectionId: projection.id,
            amount: projectionClassification?.amount ?? "0",
            currency: projection.currency,
          },
        ];
      }
    );

    return R.mergeAll([
      baseRow,
      R.fromPairs<Projection>(projectionPairs),
      R.fromPairs<Projection>(transactionPairs),
      R.fromPairs<Projection>([
        projectionMean,
        transactionMean,
        transactionTotal,
      ]),
    ]) as ProjectionRow;
  });

export const createTotalRow = (
  classificationRoot: LedgerClassification,
  projectionRows: ProjectionRow[],
  totalRow?: ProjectionRow
) => {
  const baseRow: Partial<ProjectionRow> = {
    isTotal: totalRow?.isTotal ?? true,
    id: totalRow?.id ?? `total:${classificationRoot.id}`,
    classificationName: totalRow?.classificationName ?? "TOTAL",
    // `Total ${classificationRoot.name}`.toUpperCase(),
    classificationType: totalRow?.classificationType ?? classificationRoot.type,
  };

  return projectionRows.reduce((totalRow, projectionRow) => {
    Object.keys(projectionRow)
      .filter((key) => key.startsWith("Prev") || key.startsWith("Real"))
      .forEach((key) => {
        const keyName = key as keyof ProjectionRow;
        const nonTotalRowProjection = projectionRow[keyName] as Projection;
        // console.log(baseRow.id, keyName, nonTotalRowProjection);

        if (!totalRow[keyName]) {
          (totalRow[keyName] as Projection) = {
            ...nonTotalRowProjection,
            amount: parseFloat(nonTotalRowProjection.amount).toString(),
          };
        } else {
          (totalRow[keyName] as Projection).amount = (
            parseFloat((totalRow[keyName] as Projection).amount) +
            parseFloat(nonTotalRowProjection.amount)
          ).toString();
        }
      });

    return totalRow;
  }, baseRow as ProjectionRow);
};
