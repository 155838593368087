import React, { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import LedgerProjectionDataGrid, {
  LedgerClassification,
} from "../LedgerProjectionDataGrid";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useProjection } from "../../contexts/ProjectionContext";

export interface LedgerProjectionAccordionProps {
  root: LedgerClassification;
}

const LedgerProjectionAccordion: React.FC<LedgerProjectionAccordionProps> = ({
  root,
}) => {
  const { ledgers, projections } = useProjection();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Accordion key={root.id} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <Stack direction="row" alignItems="center">
          <Chip
            label={root.type === "DEBIT" ? "Saídas" : "Entradas"}
            color={root.type === "DEBIT" ? "error" : "success"}
            sx={{ marginRight: 2 }}
          />
          <Stack>
            <Stack gap={1} direction="row" alignItems="center">
              <Typography variant="h6">{root.name}</Typography>
            </Stack>
            <Typography>{root.description}</Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {projections && ledgers && (
          <LedgerProjectionDataGrid
            classificationRoot={root}
            ledgers={ledgers.rows}
            projections={projections}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default LedgerProjectionAccordion;
