import * as R from "remeda";
import { LedgerClassificationApiRow } from "../routes/Finances/LedgerClassificationsPage";
import { LedgerClassification } from "../components/LedgerProjectionDataGrid";

export function createFlattenedTrees(rows: LedgerClassificationApiRow[]) {
  const nodes = rows ?? [];
  const roots = nodes.filter(
    (classification) => classification.parent === null
  );

  function traverse(traversingNode: LedgerClassificationApiRow) {
    const children = nodes
      .filter((node) => traversingNode.id === node.parent?.id)
      .map((childNode) => traverse(childNode));
    traversingNode.children = children;
    return traversingNode;
  }

  function traverseFlattened(
    traversingNode: LedgerClassificationApiRow,
    root = true
  ): LedgerClassificationApiRow[] {
    const children = nodes
      .filter((node) => traversingNode.id === node.parent?.id)
      .map((childNode) => {
        return traverseFlattened(childNode, false);
      });

    return R.flattenDeep([...(!root ? [traversingNode] : []), children]);
  }

  return roots?.map<LedgerClassification>((rootNode) => {
    const traversedNode = traverse(rootNode);
    const traversedChildren = traverseFlattened(rootNode);
    const mappedNode: LedgerClassification = {
      ...traversedNode,
      totalOnly: false,
      flattenedChildren: [rootNode, ...traversedChildren],
    };

    return mappedNode;
  });
}
