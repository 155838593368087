import React from "react";
import * as R from "remeda";
import styled from "@emotion/styled";

import { AuthRole, useAuth } from "../contexts/AuthContext";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowEditStartParams,
  GridRowEditStopParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  AlertProps,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export interface UserRow {
  id: number;
  name: string;
  email: string;
  role: string;
  resetPasswordToken: string;
  createdAt: string;
  deletedAt: string | null;
  isNew: boolean;
}

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// `;
interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = "-";
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        name: "",
        email: "",
        role: AuthRole.USER,
        document: "",
        createdAt: new Date(),
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Inserir Usuário
      </Button>
    </GridToolbarContainer>
  );
}

export default function Users() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [rows, setRows] = React.useState<UserRow[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState<UserRow | undefined>();
  const [rowToResetPassword, setRowToResetPassword] = React.useState<
    UserRow | undefined
  >();
  const [isResetPasswordLoading, setIsResetPasswordLoading] =
    React.useState(false);
  const [resetPasswordLink, setResetPasswordLink] = React.useState<
    string | undefined
  >();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const resetPasswordInputRef = React.createRef<HTMLInputElement>();

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  // Fetch users from API
  React.useEffect(() => {
    if (auth.decodedToken!.role === AuthRole.USER) {
      // TODO: navigate to 403 or 404 page
      navigate("/timesheet");
      return;
    }

    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/users`);
    url.searchParams.append("page", page.toString());
    url.searchParams.append("size", pageSize.toString());

    fetch(url, {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          auth.signOut();
        }

        return response;
      })
      .then((data) => data.json())
      .then((data) => {
        setRowCount(data.pagination.totalCount);
        setRows(data.items.map((d: UserRow) => ({ ...d, isNew: false })));
      })
      .catch((error: Error) => {
        console.warn(error.message);
        setSnackbar({
          children: "Falha ao listar usuários",
          severity: "error",
        });
      });
  }, [page, pageSize]);

  React.useEffect(() => {
    if (!rowToResetPassword) {
      return;
    }

    setIsResetPasswordLoading(true);
    const url = new URL(
      `${process.env.REACT_APP_API_BASE_URL}/users/${rowToResetPassword.id}/reset-password`
    );
    fetch(url, {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
      method: "POST",
    })
      .then((response) => {
        if (response.status === 401) {
          auth.signOut();
        }

        return response;
      })
      .then((data) => data.json())
      .then(({ resetPasswordToken }) =>
        setResetPasswordLink(
          `https://timesheet.carneirosampaio.com.br/reset-password/${resetPasswordToken}`
        )
      )
      .catch((error: Error) => {
        console.error(error.message);
        setRowToResetPassword(undefined);
        setSnackbar({
          children: "Algo deu errado, tente novamente mais tarde",
          severity: "error",
        });
      })
      .finally(() => {
        setIsResetPasswordLoading(false);
      });
  }, [rowToResetPassword]);

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handlePasswordClick = (id: GridRowId) => () => {
    setRowToResetPassword(rows.find((row) => row.id === id));
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRowToDelete(rows.find((row) => row.id === id));
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const columns: GridColumns<UserRow> = [
    {
      field: "id",
      headerName: "ID Usuário",
      width: 150,
      editable: false,
    },
    {
      field: "name",
      headerName: "Nome Completo",
      flex: 1,
      minWidth: 300,
      editable: true,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      width: 180,
      editable: true,
    },
    {
      field: "role",
      type: "singleSelect",
      valueOptions: [AuthRole.ADMIN, AuthRole.USER],
      headerName: "Função",
      minWidth: 100,
      editable: true,
    },
    {
      field: "createdAt",
      type: "dateTime",
      valueFormatter: ({ value }) =>
        !!value ? moment(value).format("DD/MM/YYYY HH:mm") : "-",
      valueGetter: ({ value }) => value && new Date(value),
      headerName: "Data de Criação",
      minWidth: 160,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 120,
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Salvar">
                  <SaveIcon />
                </Tooltip>
              }
              label="Salvar"
              onClick={handleSaveClick(params.id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancelar">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancelar"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Editar">
                <EditIcon />
              </Tooltip>
            }
            label="Editar"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Recuperar Senha">
                <KeyIcon />
              </Tooltip>
            }
            label="Recuperar Senha"
            className="textPrimary"
            onClick={handlePasswordClick(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Deletar">
                <DeleteIcon />
              </Tooltip>
            }
            label="Deletar"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleRowModesModelChange = (newModel: GridRowModesModel) => {
    setRowModesModel(newModel);
  };

  const handleRowEditStart = (
    _params: GridRowEditStartParams,
    event: MuiEvent
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (
    _params: GridRowEditStopParams<UserRow>,
    event: MuiEvent
  ) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (
    newRow: GridRowModel<UserRow>,
    oldRow: GridRowModel<UserRow>
  ) => {
    const { isNew, ...newRowData } = newRow;
    let updatedRow: UserRow;

    const getResponseBody = async (response: Response) => {
      if (response.status === 401) {
        auth.signOut();
        throw new Error("Unauthenticated");
      }

      const body = await response.json();

      if (response.status === 400) {
        const codedMessage: string = body.message[0];

        if (codedMessage === "email is already taken") {
          throw new Error("Este e-mail já está sendo utilizado");
        }

        if (codedMessage.endsWith("should not be empty")) {
          let field: string;
          const message = "não pode ser vazio";
          if (codedMessage.startsWith("name")) {
            field = '"Nome Completo"';
          } else if (codedMessage.startsWith("email")) {
            field = '"E-mail"';
          } else {
            field = codedMessage.split(" ")[0];
          }

          throw new Error(`O campo ${field} ${message}`);
        }
      }

      if (![200, 201].includes(response.status)) {
        console.warn(`Failed to insert/modify user: ${response.status}`);
        throw new Error(
          "Alguma coisa inesperada aconteceu, tente novamente mais tarde."
        );
      }

      return body;
    };

    if (isNew) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/users`,
        {
          body: JSON.stringify(
            R.omit(newRowData, ["id", "resetPasswordToken", "createdAt"])
          ),
          headers: {
            Authorization: `Bearer ${auth.encodedToken!}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );

      const responseBody = await getResponseBody(response);
      updatedRow = { ...responseBody, isNew: false };
      setSnackbar({
        children: "Usuário inserido com sucesso!",
        severity: "success",
      });
    } else {
      const compareEntries = (a: [string, any], b: [string, any]) =>
        a[0] === b[0] && a[1] === b[1];
      const diff = R.differenceWith(
        Object.entries(newRow),
        Object.entries(oldRow),
        compareEntries
      );

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/users/${newRow.id}`,
        {
          body: JSON.stringify(R.fromPairs(diff)),
          headers: {
            Authorization: `Bearer ${auth.encodedToken!}`,
            "Content-Type": "application/json",
          },
          method: "PATCH",
        }
      );

      await getResponseBody(response);
      updatedRow = { ...newRow, isNew: false };
      setSnackbar({
        children: "Usuário atualizado com sucesso!",
        severity: "success",
      });
    }

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    return updatedRow;
  };

  const handleProcessRowUpdateError = (error: Error) => {
    setSnackbar({ children: error.message, severity: "error" });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handlePasswordDialogClose = () => {
    setRowToResetPassword(undefined);
  };

  const handleRemoveDialogClose = () => {
    setRowToDelete(undefined);
  };

  const handleRemoveDialogAction = React.useCallback(() => {
    if (!rowToDelete) {
      return;
    }

    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/${rowToDelete.id}`, {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
      method: "DELETE",
    })
      .then((data) => {
        if (data.status !== 200) {
          throw new Error("Failed Delete");
        }

        setRows(rows.filter((row) => row.id !== rowToDelete.id));
        setSnackbar({
          children: "Usuário removido com sucesso",
          severity: "success",
        });
      })
      .catch((error: Error) => {
        console.warn(error.message);
        setSnackbar({
          children: "Falha ao remover usuário",
          severity: "error",
        });
      })
      .finally(() => {
        setRowToDelete(undefined);
      });
  }, [rowToDelete]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h6" gutterBottom>
        Lista de Usuários
      </Typography>
      <Paper>
        <DataGrid
          autoHeight
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          localeText={{
            // Rows selected footer text
            footerRowSelected: (count) =>
              count !== 1
                ? `${count.toLocaleString()} linhas selecionadas`
                : `${count.toLocaleString()} linha selecionada`,
            footerTotalRows: "Total páginas: ",
            footerTotalVisibleRows: (visibleCount, totalCount) =>
              `${visibleCount.toString()} de ${totalCount.toLocaleString()} linhas`,
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `Linhas ${from} até ${to} (total: ${count})`,
            },
          }}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          pagination
          paginationMode="server"
          page={page}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[]}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          experimentalFeatures={{ newEditingApi: true }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
        {!!rowToResetPassword && (
          <Dialog
            open
            onClose={handlePasswordDialogClose}
            aria-labelledby="password-dialog-title"
            aria-describedby="password-dialog-description"
          >
            <DialogTitle id="password-dialog-title">
              Recuperar senha
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="password-dialog-description">
                {`Informe o link abaixo para que o usuário ${rowToResetPassword.name} recupere sua respectiva senha.`}
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: "auto",
                  width: "fit-content",
                }}
              >
                {isResetPasswordLoading ? (
                  <CircularProgress />
                ) : (
                  <FormControl
                    sx={{ mt: 2, minWidth: 250, width: 350 }}
                    fullWidth
                  >
                    <InputLabel htmlFor="password-recovery-link">
                      Link de Recuperação de Senha
                    </InputLabel>
                    <FilledInput
                      readOnly
                      inputRef={resetPasswordInputRef}
                      fullWidth
                      defaultValue={resetPasswordLink}
                      id="password-recovery-link"
                      type="text"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="copiar link de recuperação de senha"
                            onClick={() => {
                              resetPasswordInputRef.current!.select();
                              // resetPasswordInputRef.current!.setSelectionRange(resetPasswordInputRef.current!.value.length, resetPasswordInputRef.current!.value.length);
                              resetPasswordInputRef.current!.focus();
                              navigator.clipboard.writeText(
                                resetPasswordInputRef.current!.value
                              );
                              setSnackbar({
                                children: "Link copiado",
                                severity: "info",
                              });
                            }}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                )}
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {!!rowToDelete && (
          <Dialog
            open
            onClose={handleRemoveDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Você tem certeza que deseja remover este usuário?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Você está prestes a remover o usuário ${rowToDelete.name} (ID: ${rowToDelete.id})`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRemoveDialogClose} autoFocus>
                Cancelar
              </Button>
              <Button color="error" onClick={handleRemoveDialogAction}>
                Remover
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
    </Container>
  );
}
