import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import React from "react";

interface LedgerToolbarProps {
  onInsertRow: () => void;
}

function LedgerTransactionsToolbar(props: LedgerToolbarProps) {
  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <Box>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={props.onInsertRow}
        >
          Inserir Transação
        </Button>
      </Box>
    </GridToolbarContainer>
  );
}

export default LedgerTransactionsToolbar;
