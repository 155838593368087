import * as React from "react";
import axios, { AxiosError } from "axios";
import * as jose from "jose";
import useLocalState from "@phntms/use-local-state";

export enum AuthRole {
  ADMIN = "admin",
  USER = "user",
}

declare module "jose" {
  export interface JWTPayload {
    email: string;
    role: AuthRole;
  }
}

export type AuthContextType = {
  authError?: AxiosError;
  checkResetPassword: (resetPasswordToken: string) => Promise<void>;
  decodedToken?: jose.JWTPayload;
  encodedToken?: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  isLoggingOut: boolean;
  logout: () => void;
  resetAuthError: () => void;
  resetPassword: (
    resetPasswordToken: string,
    newPassword: string
  ) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: (isLoggingOut?: boolean) => void;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [encodedToken, setEncodedToken] = useLocalState<string | undefined>(
    "encodedToken",
    undefined
  );
  const [decodedToken, setDecodedToken] = React.useState<
    jose.JWTPayload | undefined
  >(() => (encodedToken ? jose.decodeJwt(encodedToken) : undefined));

  const [authError, setAuthError] = React.useState<AxiosError | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = React.useState<boolean>(false);
  const isAuthenticated = React.useMemo(
    () => encodedToken !== undefined && decodedToken !== undefined,
    [encodedToken, decodedToken]
  );

  const resetAuthError = () => {
    setAuthError(undefined);
  };

  const checkResetPassword = async (resetPasswordToken: string) => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/reset-password/${resetPasswordToken}`
      );

      if (response.data === false) {
        throw new Error("Link de Recuperação de senha inválido");
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setAuthError(error);
      } else {
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async (
    resetPasswordToken: string,
    newPassword: string
  ) => {
    setIsLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/reset-password/${resetPasswordToken}`,
        { newPassword }
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        setAuthError(error);
      } else {
        console.error({ error });
      }

      throw new Error("Reset Password Failed");
    } finally {
      setIsLoading(false);
    }
  };

  const signIn = async (email: string, password: string) => {
    setIsLoading(true);
    setIsLoggingOut(false);

    try {
      const response = await axios.post<{ access_token: string }>(
        `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
        { username: email, password }
      );
      setAuthError(undefined);
      setEncodedToken(response.data.access_token);
      setDecodedToken(jose.decodeJwt(response.data.access_token));
    } catch (error) {
      if (error instanceof AxiosError) {
        setAuthError(error);
      } else {
        console.error({ error });
      }

      throw new Error("Login Failed");
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = () => {
    setEncodedToken(undefined);
  };

  const logout = () => {
    setIsLoggingOut(true);
  };

  const unload = React.useCallback(() => {
    if (isLoggingOut) {
      signOut();
    }
  }, [isLoggingOut]);

  React.useEffect(() => {
    window.addEventListener("unload", unload);

    return () => {
      window.removeEventListener("unload", unload);
    };
  }, [unload]);

  const value: AuthContextType = {
    authError,
    checkResetPassword,
    decodedToken,
    encodedToken,
    isAuthenticated,
    isLoading,
    isLoggingOut,
    logout,
    resetAuthError,
    resetPassword,
    signIn,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return React.useContext(AuthContext);
}

export default AuthProvider;
