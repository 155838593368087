import React from "react";

import {
  InsertDriveFile as FileIcon,
  FileUpload as FileUploadIcon,
} from "@mui/icons-material";

import { Box, Button, Stack, Typography } from "@mui/material";
import LinearProgressWithLabel from "../LinearProgressWithLabel";

interface FileUploadProps {
  accept?: string;
  progress?: number;
  upload: (file: File) => void;
}

export default function FileUpload(props: FileUploadProps) {
  const [file, setFile] = React.useState<File | null>(null);
  // const [progress, setProgress] = React.useState<number | undefined>(12);
  const inputFile = React.useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    inputFile.current?.click();
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.stopPropagation();
    event.preventDefault();

    console.log("handleFileChange", event.target.files);
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
    }
  };

  const handleUpload = React.useCallback(() => {
    console.log("handleUpload", file);
    if (!file) return;
    props.upload(file);
  }, [file, props.upload]);

  return (
    <Stack
      alignItems="center"
      border={file ? "1px solid black" : undefined}
      borderRadius={2}
      my={2}
      py={2}
    >
      <input
        type="file"
        id="file"
        ref={inputFile}
        accept={props.accept}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {file && (
        <Stack direction="row" alignItems="center" sx={{ pb: 2 }}>
          <FileIcon />
          <Typography>{file.name}</Typography>
        </Stack>
      )}
      {props.progress ? (
        <Box px={2} width="100%">
          <LinearProgressWithLabel value={props.progress} />
        </Box>
      ) : (
        <Stack direction="row" alignItems="center" gap={1}>
          <Button
            startIcon={<FileIcon />}
            variant="outlined"
            onClick={handleSelectFile}
          >
            {file ? "Subsituir Arquivo" : "Escolher Arquivo"}
          </Button>
          {file && (
            <Button color="primary" onClick={handleUpload} variant="contained">
              <FileUploadIcon />
              Enviar
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
