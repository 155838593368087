import React, { useEffect, useMemo } from "react";
import * as R from "remeda";

import styled from "@emotion/styled";

import {
  Alert,
  AlertProps,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridRowModel,
  GridRowModes,
} from "@mui/x-data-grid";
import {
  Cancel as CancelIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import useRowModesModel from "../../hooks/useRowModesModel";
import { useAuth } from "../../contexts/AuthContext";
import { useFetch } from "use-http";
import { useNavigate, useParams } from "react-router-dom";
import GridCellExpand from "../../components/GridCellExpand";
import LedgerClassificationToolbar from "../../components/Finances/LedgerClassificationToolbar";
import { LoadingButton } from "@mui/lab";

type Snackbar = Pick<AlertProps, "children" | "severity">;

export type LedgerClassificationApiRow = {
  id: string;
  name: string;
  description: string;
  type: string;
  parent?: LedgerClassificationApiRow;
  children?: LedgerClassificationApiRow[];
  isNew?: boolean;
};

export type LedgerClassificationApi = {
  count: number;
  paginationOptions?: {
    page: number;
    size: number;
  };
  parent?: LedgerClassificationApiRow;
  rows: LedgerClassificationApiRow[];
};

export default function LedgerClassificationsPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { parentId } = useParams();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [snackbar, setSnackbar] = React.useState<Snackbar | null>(null);

  const handleCloseSnackbar = () => {
    setSnackbar(null);
  };

  const [
    rowModesModel,
    {
      onRowEditStart,
      onRowEditStop,
      onRowModesModelChange,
      startEditingRow,
      stopEditingRow,
    },
  ] = useRowModesModel();

  const { delete: deleteClassification, loading: isDeleting } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/ledger-classification`,
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken}`,
      },
      method: "DELETE",
    }
  );

  const [rowToDelete, setRowToDelete] = React.useState<
    LedgerClassificationApiRow | undefined
  >();

  const handleRemoveDialogOpen = (row: LedgerClassificationApiRow) => () => {
    setRowToDelete(row);
  };

  const handleRemoveDialogClose = () => {
    setRowToDelete(undefined);
  };

  const handleRemoveDialogAction = () => {
    if (!rowToDelete) return;
    deleteClassification(`/${rowToDelete?.id}`)
      .then(() => {
        setRows((oldRows) =>
          oldRows.filter((row) => row.id !== rowToDelete.id)
        );

        setSnackbar({
          children: "Classificação removida com sucesso",
          severity: "success",
        });
      })
      .catch((error: Error) => {
        console.warn(error.message);
        setSnackbar({
          children: "Falha ao remover classificação",
          severity: "error",
        });
      })
      .finally(() => {
        handleRemoveDialogClose();
      });
  };

  const fetchApiUrl = useMemo(
    () =>
      [
        `${process.env.REACT_APP_API_BASE_URL}/ledger-classification?page=${page}&size=${pageSize}&include-parent=true`,
        ...(parentId ? [`parent-id=${parentId}`] : []),
      ].join("&"),
    [page, pageSize, parentId]
  );
  const { data: classifications, loading } = useFetch<LedgerClassificationApi>(
    fetchApiUrl,
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken}`,
      },
      method: "get",
    },
    [fetchApiUrl]
  );
  const [rows, setRows] = React.useState<LedgerClassificationApiRow[]>([]);

  const columns: GridColumns<LedgerClassificationApiRow> = [
    {
      field: "name",
      headerName: "Nome",
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <GridCellExpand
          value={params.value || ""}
          width={params.colDef.computedWidth}
        />
      ),
    },
    {
      field: "description",
      headerName: "Descrição",
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <GridCellExpand
          value={params.value || ""}
          width={params.colDef.computedWidth}
        />
      ),
    },
    {
      field: "type",
      type: "singleSelect",
      valueOptions: classifications?.parent
        ? classifications?.parent.type === "DEBIT"
          ? ["Saída"]
          : ["Entrada"]
        : ["Entrada", "Saída"],
      headerName: "Tipo",
      minWidth: 150,
      editable: true,
      valueSetter: (params) => ({
        ...params.row,
        type: params.value === "Entrada" ? "CREDIT" : "DEBIT",
      }),
      valueGetter: (params) => {
        switch (params.row.type) {
          case "CREDIT":
            return "Entrada";
          case "DEBIT":
            return "Saída";
          default:
            return params.row.type;
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      minWidth: 140,
      getActions: (params) => {
        const isEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Salvar">
                  <SaveIcon />
                </Tooltip>
              }
              label="Salvar"
              onClick={stopEditingRow(params.id, false)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancelar">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancelar"
              onClick={cancelEditingRow(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Ver">
                <VisibilityIcon />
              </Tooltip>
            }
            label="Ver"
            onClick={handleViewClassification(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Editar">
                <EditIcon />
              </Tooltip>
            }
            label="Editar"
            onClick={startEditingRow(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Deletar">
                <DeleteIcon />
              </Tooltip>
            }
            label="Deletar"
            onClick={handleRemoveDialogOpen(params.row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleProcessRowUpdateError = (error: Error) => {
    setSnackbar({
      children: error.message,
      severity: "error",
    });
  };

  const processRowUpdate = async (
    newRow: GridRowModel<LedgerClassificationApiRow>,
    oldRow: GridRowModel<LedgerClassificationApiRow>
  ) => {
    const { isNew, id, ...newRowData } = newRow;
    let resultRow: LedgerClassificationApiRow;

    const getResponseBody = async (response: Response) => {
      if (response.status === 401) {
        auth.signOut();
        throw new Error("Unauthenticated");
      }

      const body = await response.json();

      if (response.status === 400) {
        const codedMessage: string = body.message[0];

        if (
          codedMessage.endsWith("should not be empty") ||
          codedMessage.endsWith("must be a valid enum value")
        ) {
          let field: string;
          const message = "não pode ser vazio";
          if (codedMessage.startsWith("name")) {
            field = '"Nome"';
          } else if (codedMessage.startsWith("description")) {
            field = '"Descrição"';
          } else {
            field = codedMessage.split(" ")[0];
          }

          throw new Error(`O campo ${field} ${message}`);
        }
      }

      if (![200, 201].includes(response.status)) {
        console.warn(`Failed to insert/modify timesheet: ${response.status}`);
        throw new Error(
          "Alguma coisa inesperada aconteceu, tente novamente mais tarde."
        );
      }

      return body;
    };

    console.log({ isNew });
    if (isNew) {
      const body = JSON.stringify({
        ...newRowData,
        parentId,
      });
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/ledger-classification`,
        {
          body,
          headers: {
            Authorization: `Bearer ${auth.encodedToken}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );

      const responseBody = await getResponseBody(response);

      resultRow = { ...responseBody, isNew: false };
      setSnackbar({
        children: "Classificação criada com sucesso!",
        severity: "success",
      });
    } else {
      const compareEntries = (a: [string, any], b: [string, any]) =>
        a[0] === b[0] && a[1] === b[1];

      const getComparableRowEntries = (row: LedgerClassificationApiRow) => {
        const rowData = R.omit(row, ["isNew", "id"]);

        const comparableRow: Partial<LedgerClassificationApiRow> = rowData;

        return Object.entries(comparableRow);
      };

      const diff = R.differenceWith(
        getComparableRowEntries(newRow),
        getComparableRowEntries(oldRow),
        compareEntries
      );

      console.log({ diff });
      if (diff.length > 0) {
        const body = JSON.stringify(R.fromPairs(diff));

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/ledger-classification/${id}`,
          {
            body,
            headers: {
              Authorization: `Bearer ${auth.encodedToken}`,
              "Content-Type": "application/json",
            },
            method: "PATCH",
          }
        );

        const responseBody = await getResponseBody(response);
        setSnackbar({
          children: "Classificação atualizada com sucesso!",
          severity: "success",
        });
        resultRow = { ...newRow, ...responseBody, isNew: false };
      } else {
        resultRow = oldRow;
      }
    }

    setRows((rows) =>
      rows.map((row) => (row.id === newRow.id ? resultRow : row))
    );

    return resultRow;
  };

  useEffect(() => {
    if (!classifications) return;
    setRows(classifications.rows);
  }, [classifications?.rows]);

  const startInsertingRow = React.useCallback(() => {
    const insertingRows =
      rows.filter((transaction) => transaction.id.startsWith("new")) ?? [];

    const id = `new-${insertingRows.length}`;

    const newRow: LedgerClassificationApiRow = {
      isNew: true,
      id,
      name: "",
      type: classifications?.parent?.type ?? "CREDIT",
      description: "",
    };

    setRows((oldRows) => [...oldRows, newRow]);

    startEditingRow(id)();
  }, [rows]);

  const cancelEditingRow = React.useCallback(
    (id: GridRowId) => () => {
      stopEditingRow(id, true)();

      const row = rows.find((row) => row.id === id);
      if (row?.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [rows, stopEditingRow]
  );

  const handleViewClassification = (id: GridRowId) => () => {
    navigate(`/finances/classifications/${id}`, {
      replace: true,
    });
  };

  const breadcrumbNavigate =
    (href: string) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      setRows([]);
      navigate(href);
    };

  return (
    <Container maxWidth="xl">
      {classifications?.parent && parentId && (
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Link
            underline="hover"
            color="inherit"
            onClick={breadcrumbNavigate("/finances/classifications")}
          >
            Classificações Macros
          </Link>
          {classifications.parent.parent && (
            <Link
              underline="hover"
              color="inherit"
              onClick={breadcrumbNavigate(
                `/finances/classifications/${classifications.parent.parent.id}`
              )}
            >
              {classifications.parent.parent.name}
            </Link>
          )}
          {/* <Typography color="text.primary">
            {classifications.parent.name}
          </Typography> */}
        </Breadcrumbs>
      )}
      <Typography variant="h5" mb={2}>
        {classifications?.parent
          ? `Classificações Micros de ${classifications.parent.name}`
          : "Classificações Macros"}
      </Typography>
      <Paper>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" minHeight="60vh">
            <Stack direction="row" gap={1}>
              <CircularProgress size={32} />
              <Typography lineHeight={2}>Carregando...</Typography>
            </Stack>
          </Stack>
        ) : (
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nenhuma Classificação existente
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nenhuma Classificação encontrada
                </Stack>
              ),
              Toolbar: LedgerClassificationToolbar,
            }}
            componentsProps={{
              toolbar: {
                onInsertRow: startInsertingRow,
              },
            }}
            localeText={{
              // Rows selected footer text
              footerRowSelected: (count) =>
                count !== 1
                  ? `${count.toLocaleString()} linhas selecionadas`
                  : `${count.toLocaleString()} linha selecionada`,
              footerTotalRows: "Total páginas: ",
              footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toString()} de ${totalCount.toLocaleString()} linhas`,
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `Linhas ${from} até ${to} (total: ${count})`,
              },
            }}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={onRowModesModelChange}
            onRowEditStart={onRowEditStart}
            onRowEditStop={onRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            pagination
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            rowCount={classifications?.count ?? 0}
            onPageChange={setPage}
            rowsPerPageOptions={[100]}
            onPageSizeChange={setPageSize}
            experimentalFeatures={{ newEditingApi: true }}
          />
        )}
      </Paper>
      {rowToDelete && (
        <Dialog
          open
          onClose={handleRemoveDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Você tem certeza que deseja remover esta Transação?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Você está prestes a remover a Classificação "${rowToDelete.name}".`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemoveDialogClose} autoFocus>
              Cancelar
            </Button>
            <LoadingButton
              loading={isDeleting}
              color="error"
              onClick={handleRemoveDialogAction}
            >
              Remover
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
    </Container>
  );
}
