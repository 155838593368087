import React from "react";

import {
  GridRowEditStartParams,
  GridRowEditStopParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  MuiEvent,
} from "@mui/x-data-grid";

export type UseRowModesModelReturn = [
  GridRowModesModel,
  {
    onRowModesModelChange: React.Dispatch<
      React.SetStateAction<GridRowModesModel>
    >;
    onRowEditStart: (params: GridRowEditStartParams, event: MuiEvent) => void;
    onRowEditStop: (params: GridRowEditStopParams, event: MuiEvent) => void;
    startEditingRow: (id: GridRowId) => () => void;
    stopEditingRow: (id: GridRowId, ignoreModifications: boolean) => () => void;
  }
];

export default function useRowModesModel(): UseRowModesModelReturn {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const startEditingRow = (id: GridRowId) => () => {
    // console.log("start editing row", id);
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const stopEditingRow =
    (id: GridRowId, ignoreModifications: boolean = true) =>
    () => {
      // console.log("stop editing row", id);
      setRowModesModel((prev) => ({
        ...prev,
        [id]: { mode: GridRowModes.View, ignoreModifications },
      }));
    };

  const onEditChange = (
    _params: GridRowEditStartParams | GridRowEditStopParams,
    event: MuiEvent
  ) => {
    event.defaultMuiPrevented = true;
  };

  return [
    rowModesModel,
    {
      onRowModesModelChange: setRowModesModel,
      onRowEditStart: onEditChange,
      onRowEditStop: onEditChange,
      startEditingRow,
      stopEditingRow,
    },
  ];
}
