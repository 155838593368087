import { CssBaseline } from "@mui/material";
import * as React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AuthProvider, { useAuth } from "./contexts/AuthContext";
import TimesheetProvider from "./contexts/TimesheetContext";
import DashboardLayout from "./layouts/DashboardLayout";
import ManageCustomers from "./routes/Customers";
import SignIn from "./routes/Login";
import ResetPassword from "./routes/ResetPassword";
import Timesheet from "./routes/Timesheet";
import ManageUsers from "./routes/Users";
import LedgerPage from "./routes/Finances/LedgerPage";
import LedgerTransactionsPage from "./routes/Finances/LedgerTransactionsPage";
import LedgerClassificationsPage from "./routes/Finances/LedgerClassificationsPage";
import LedgerProjectionPage from "./routes/Finances/LedgerProjectionPage";
import ProjectionProvider from "./contexts/ProjectionContext";

interface ProtectedRouteProps {
  children: JSX.Element;
}

// 34840e45-0c9c-487a-8ba4-aeb67b353a11
// https://www.robinwieruch.de/react-router-private-routes/
// https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isAuthenticated && !auth.isLoggingOut) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
};

export default function App() {
  return (
    <AuthProvider>
      <CssBaseline />
      <Routes>
        <Route path="sign-in" element={<SignIn />} />
        <Route
          path="reset-password/:resetPasswordToken"
          element={<ResetPassword />}
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <TimesheetProvider>
                <DashboardLayout />
              </TimesheetProvider>
            </ProtectedRoute>
          }
        >
          <Route index element={<Timesheet />} />
          <Route path="timesheet" element={<Timesheet />} />
          <Route path="customers" element={<ManageCustomers />} />
          <Route path="users" element={<ManageUsers />} />
          <Route
            path="finances/projections"
            element={
              <ProjectionProvider>
                <LedgerProjectionPage />
              </ProjectionProvider>
            }
          />
          <Route
            path="finances/classifications/:parentId?"
            element={<LedgerClassificationsPage />}
          />
          <Route path="finances/ledger" element={<LedgerPage />} />
          <Route
            path="finances/ledger/:ledgerId"
            element={<LedgerTransactionsPage />}
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
