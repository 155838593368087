import React, { useMemo } from "react";
import * as R from "remeda";

import styled from "@emotion/styled";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertProps,
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Link,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridColumns,
  GridRowId,
  GridRowModel,
  GridRowModes,
} from "@mui/x-data-grid";
import {
  Cancel as CancelIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  InfoOutlined as InfoIcon,
  ExpandMore,
  Save as SaveIcon,
  AttachMoney,
  // ArrowRightAlt,
  // ArrowForward,
} from "@mui/icons-material";
import useRowModesModel from "../../hooks/useRowModesModel";
import { useAuth } from "../../contexts/AuthContext";
import { useFetch } from "use-http";
import { useParams } from "react-router-dom";
import DateEditInputCell from "../../components/DataGridEditCells/DateEditInputCell";
import GridCellExpand from "../../components/GridCellExpand";
import LedgerTransactionsToolbar from "../../components/Finances/LedgerToolbar";
import CurrencyEditInputCell from "../../components/DataGridEditCells/CurrencyEditInputCell";
import { LoadingButton } from "@mui/lab";
import {
  LedgerClassificationApi,
  LedgerClassificationApiRow,
} from "./LedgerClassificationsPage";
import { LedgerApiRow } from "./LedgerPage";
// import FinanceStatistic from "../../components/FinanceStatistic";
import { toCurrencyString } from "../../utils/finance";
import { createFlattenedTrees } from "../../utils/classificationTree";
import { useTimesheet } from "../../contexts/TimesheetContext";
import { CustomerRow } from "../Customers";
import { Customer } from "../Timesheet";

const dateFormatter = new Intl.DateTimeFormat("pt-BR", {
  dateStyle: "short",
});

type Snackbar = Pick<AlertProps, "children" | "severity">;

export type LedgerTransactionApiRow = {
  id: string;
  date: Date | string | null;
  amount: string;
  description: string;
  externalId: string | null;
  primaryClassification: LedgerClassificationApiRow | string | null;
  secondaryClassification: LedgerClassificationApiRow | string | null;
  customer: Customer | number | null;
  type: string;
  currency: string;
  isNew?: boolean;
};
export type LedgerTransactionApi = {
  count: number;
  paginationOptions: {
    page: number;
    size: number;
  };
  ledger: LedgerApiRow;
  rows: LedgerTransactionApiRow[];
  summary: {
    currency: string;
    startBalance: string;
    endBalance: string;
    transactionsBalance: string;
  };
};

export default function LedgerTransactionsPage() {
  const auth = useAuth();
  const timesheet = useTimesheet();
  const { ledgerId } = useParams();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [snackbar, setSnackbar] = React.useState<Snackbar | null>(null);

  const handleCloseSnackbar = () => {
    setSnackbar(null);
  };

  const apiUrl = useMemo(
    () =>
      `${process.env.REACT_APP_API_BASE_URL}/ledger/${ledgerId}/transaction?page=${page}&size=${pageSize}`,
    [page, pageSize]
  );
  const { data: transactions, loading: loadingTransactions } =
    useFetch<LedgerTransactionApi>(
      apiUrl,
      {
        headers: {
          Authorization: `Bearer ${auth.encodedToken!}`,
        },
        method: "GET",
      },
      []
    );

  const {
    data: primaryClassifications,
    loading: loadingPrimaryClassifications,
  } = useFetch<LedgerClassificationApi>(
    `${process.env.REACT_APP_API_BASE_URL}/ledger-classification?flattened=true&include-parent=true`,
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken}`,
      },
      method: "GET",
    },
    []
  );

  const secondaryClassifications = useMemo(
    () => createFlattenedTrees(primaryClassifications?.rows ?? []),
    [primaryClassifications]
  );

  const [rowToDelete, setRowToDelete] = React.useState<
    LedgerTransactionApiRow | undefined
  >();

  const handleRemoveDialogOpen = (row: LedgerTransactionApiRow) => () => {
    setRowToDelete(row);
  };

  const handleRemoveDialogClose = () => {
    setRowToDelete(undefined);
  };

  const handleRemoveDialogAction = () => {
    if (!rowToDelete) return;
    deleteTransaction(`/${rowToDelete?.id}`)
      .then(() => {
        setRows((oldRows) =>
          oldRows.filter((row) => row.id !== rowToDelete.id)
        );

        setSnackbar({
          children: "Transação removida com sucesso",
          severity: "success",
        });
      })
      .catch((error: Error) => {
        console.warn(error.message);
        setSnackbar({
          children: "Falha ao remover transação",
          severity: "error",
        });
      })
      .finally(() => {
        handleRemoveDialogClose();
      });
  };

  const { delete: deleteTransaction, loading: isDeleting } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/ledger/${ledgerId}/transaction`,
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
      method: "DELETE",
    }
  );

  const [rows, setRows] = React.useState<LedgerTransactionApiRow[]>(
    transactions?.rows ?? []
  );

  const [
    rowModesModel,
    {
      onRowEditStart,
      onRowEditStop,
      onRowModesModelChange,
      startEditingRow,
      stopEditingRow,
    },
  ] = useRowModesModel();

  React.useEffect(() => {
    if (!transactions) return;
    setRows(transactions.rows);
  }, [transactions?.rows]);

  const startInsertingRow = React.useCallback(() => {
    const insertingRows =
      rows.filter((transaction) => transaction.id.startsWith("new")) ?? [];

    const id = `new-${insertingRows.length}`;

    const newRow: LedgerTransactionApiRow = {
      isNew: true,
      id,
      amount: "",
      date: null,
      description: "",
      primaryClassification: null,
      secondaryClassification: null,
      customer: null,
      externalId: null,
      currency: transactions?.summary.currency ?? "BRL",
      type: "CREDIT",
    };

    setRows((oldRows) => [...oldRows, newRow]);

    startEditingRow(id)();
  }, [rows, transactions?.summary]);

  const cancelEditingRow = React.useCallback(
    (id: GridRowId) => () => {
      stopEditingRow(id, true)();

      const row = rows.find((row) => row.id === id);
      if (row?.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [rows]
  );

  const isCellEditable = (
    params: GridCellParams<any, LedgerTransactionApiRow>
  ): boolean =>
    !params.row.externalId ||
    (params.row.externalId !== null &&
      [
        "primaryClassification",
        "secondaryClassification",
        "customer",
        "observation",
      ].includes(params.field));

  const baseClassificationColumn: Partial<GridColDef<LedgerTransactionApiRow>> =
    {
      type: "singleSelect",
      editable: true,
      minWidth: 150,
      renderCell: (params) => (
        <GridCellExpand
          value={params.value || ""}
          width={params.colDef.computedWidth}
        />
      ),
    };

  const columns: GridColumns<LedgerTransactionApiRow> = useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        getActions: (params) => {
          const isEditMode =
            rowModesModel[params.id]?.mode === GridRowModes.Edit;

          if (isEditMode) {
            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Salvar">
                    <SaveIcon />
                  </Tooltip>
                }
                label="Salvar"
                onClick={stopEditingRow(params.id, false)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title="Cancelar">
                    <CancelIcon />
                  </Tooltip>
                }
                label="Cancelar"
                onClick={cancelEditingRow(params.id)}
                color="inherit"
              />,
            ];
          }
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Editar">
                  <EditIcon />
                </Tooltip>
              }
              label="Editar"
              onClick={startEditingRow(params.id)}
              color="inherit"
            />,
            <GridActionsCellItem
              disabled={params.row.externalId !== null}
              icon={
                <Tooltip title="Deletar">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Deletar"
              onClick={handleRemoveDialogOpen(params.row)}
              color="inherit"
            />,
          ];
        },
      },
      {
        field: "date",
        type: "date",
        headerName: "Data",
        editable: true,
        minWidth: 160,
        renderEditCell: (params) => <DateEditInputCell {...params} />,
        valueGetter: ({ value, ...params }) => {
          const date = new Date(value);
          return (
            value &&
            new Date(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate(),
              date.getUTCHours(),
              date.getUTCMinutes(),
              date.getUTCSeconds(),
              date.getUTCMilliseconds()
            )
          );
        },
        valueFormatter: ({ value }: { value: Date }) =>
          dateFormatter.format(value),
      },
      {
        field: "description",
        headerName: "Identificação",
        editable: true,
        flex: 1,
        minWidth: 200,
        renderCell: (params) => (
          <GridCellExpand
            value={params.value || ""}
            width={params.colDef.computedWidth}
          />
        ),
      },
      {
        field: "type",
        type: "singleSelect",
        valueOptions: [
          "Crédito",
          "Débito",
          "Juros",
          "Dividendos",
          "Taxa",
          "Taxa de Serviço",
          "Depósito",
          "Caixa Eletrônico",
          "Ponto de Venda (PDV)",
          "Transferência",
          "Cheque",
          "Pagamento Eletrônico",
          "Saque",
          "Depósito Direto",
          "Débito Direto",
          "Pagamento Recorrente",
          "Em Espera",
          "Outros",
        ],
        headerName: "Tipo",
        editable: true,
        minWidth: 120,
        valueSetter: (params) => {
          let type: string;
          switch (params.value) {
            case "Crédito":
              type = "CREDIT";
              break;
            case "Débito":
              type = "DEBIT";
              break;
            case "Juros":
              type = "INTEREST";
              break;
            case "Dividendos":
              type = "DIVIDEND";
              break;
            case "Taxa":
              type = "FEE";
              break;
            case "Taxa de Serviço":
              type = "SERVICE_CHARGE";
              break;
            case "Depósito":
              type = "DEPOSIT";
              break;
            case "Caixa Eletrônico":
              type = "ATM";
              break;
            case "Ponto de Venda (PDV)":
              type = "POINT_OF_SALE";
              break;
            case "Transferência":
              type = "TRANSFER";
              break;
            case "Cheque":
              type = "CHECK";
              break;
            case "Pagamento Eletrônico":
              type = "PAYMENT";
              break;
            case "Saque":
              type = "CASH";
              break;
            case "Depósito Direto":
              type = "DIRECT_DEPOSIT";
              break;
            case "Débito Direto":
              type = "DIRECT_DEBIT";
              break;
            case "Pagamento Recorrente":
              type = "REPEAT_PAYMENT";
              break;
            case "Em Espera":
              type = "HOLD";
              break;
            case "Outros":
              type = "OTHER";
              break;
            default:
              type = params.row.type;
          }

          console.log(params.value, type);

          return {
            ...params.row,
            type,
          };
        },
        valueGetter: (params) => {
          switch (params.row.type) {
            case "CREDIT":
              return "Crédito";
            case "DEBIT":
              return "Débito";
            case "INTEREST":
              return "Juros";
            case "DIVIDEND":
              return "Dividendos";
            case "FEE":
              return "Taxa";
            case "SERVICE_CHARGE":
              return "Taxa de Serviço";
            case "DEPOSIT":
              return "Depósito";
            case "ATM":
              return "Caixa Eletrônico";
            case "POINT_OF_SALE":
              return "Ponto de Venda (PDV)";
            case "TRANSFER":
              return "Transferência";
            case "CHECK":
              return "Cheque";
            case "PAYMENT":
              return "Pagamento Eletrônico";
            case "CASH":
              return "Saque";
            case "DIRECT_DEPOSIT":
              return "Depósito Direto";
            case "DIRECT_DEBIT":
              return "Débito Direto";
            case "REPEAT_PAYMENT":
              return "Pagamento Recorrente";
            case "HOLD":
              return "Em Espera";
            case "OTHER":
              return "Outros";
          }
        },
      },
      {
        ...baseClassificationColumn,
        field: "primaryClassification",
        headerName: "Classificação",
        valueOptions: () =>
          primaryClassifications?.rows
            .filter((row) => !row.parent)
            .map((row) => ({
              label: row.name,
              value: row.id,
            })) ?? [],
        valueGetter: (params) => {
          const isEditMode =
            rowModesModel[params.id]?.mode === GridRowModes.Edit;

          if (params.row.isNew) {
            return params.value;
          }

          if (isEditMode) {
            if (typeof params.value === "string") {
              return params.value;
            }

            return (params.value as LedgerClassificationApiRow)?.id;
          }

          if (typeof params.value === "string") {
            return primaryClassifications?.rows.find(
              (classification) => classification.id === params.value
            )?.name;
          }

          if (typeof params.row.primaryClassification === "string") {
            return primaryClassifications?.rows.find(
              (classification) =>
                classification.id === params.row.primaryClassification
            )?.name;
          }

          return params.row.primaryClassification?.name;
        },
      },
      {
        ...baseClassificationColumn,
        field: "secondaryClassification",
        headerName: "Descrição",
        valueOptions: (params) => {
          const root = secondaryClassifications.find(
            (root) => root.id === params.row?.primaryClassification
          );

          return (root?.children ?? []).map((row) => ({
            label: row.name,
            value: row.id,
          }));
        },
        valueGetter: (params) => {
          const isEditMode =
            rowModesModel[params.id]?.mode === GridRowModes.Edit;

          if (params.row.isNew) {
            return params.value;
          }

          if (isEditMode) {
            if (typeof params.value === "string") {
              return params.value;
            }

            return (params.value as LedgerClassificationApiRow)?.id;
          }

          if (typeof params.value === "string") {
            return primaryClassifications?.rows.find(
              (classification) => classification.id === params.value
            )?.name;
          }

          if (typeof params.row.secondaryClassification === "string") {
            return primaryClassifications?.rows.find(
              (classification) =>
                classification.id === params.row.secondaryClassification
            )?.name;
          }

          return params.row.secondaryClassification?.name;
        },
      },
      {
        field: "observation",
        headerName: "Observação",
        editable: true,
        minWidth: 160,
      },
      {
        field: "amount",
        headerName: "Valor",
        editable: true,
        minWidth: 130,
        renderEditCell: (params) => {
          return (
            <CurrencyEditInputCell {...params} currency={params.row.currency} />
          );
        },
        valueGetter: (params) => {
          const currency = params.row.currency;
          const parsedValue = parseFloat(params.value) / 100;
          const formatter = Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency,
            signDisplay: "never",
          });
          const formattedValue = formatter.format(parsedValue);

          if (parsedValue < 0) {
            return `(${formattedValue})`;
          }

          return formattedValue;
        },
        valueSetter: (params) => {
          const amount =
            parseFloat((params.value as string).replace(",", ".")) * 100;

          const resultAmount = isNaN(amount)
            ? params.row.amount
            : amount.toString();
          // console.log("valueSetter resultAmount", { resultAmount });
          return {
            ...params.row,
            amount: resultAmount,
          };
        },
      },
      {
        field: "customer",
        headerName: "Cliente",
        editable: true,
        type: "singleSelect",
        minWidth: 260,
        valueOptions: () =>
          timesheet.customers?.map((customer) => ({
            label: customer.name,
            value: customer.id,
          })),
        valueGetter: (params) => {
          const isEditMode =
            rowModesModel[params.id]?.mode === GridRowModes.Edit;

          if (params.row.isNew) {
            return params.value;
          }

          if (isEditMode) {
            if (typeof params.value === "string") {
              return params.value;
            }

            return (params.value as Customer)?.id;
          }

          if (typeof params.value === "string") {
            return timesheet.customers.find(
              (customer) => customer.id === params.value
            )?.name;
          }

          if (typeof params.row.customer === "number") {
            return timesheet.customers.find(
              (customer) => customer.id === params.row.customer
            )?.name;
          }

          return params.row.customer?.name;
        },
      },
      {
        field: "imported",
        headerName: "Importado?",
        editable: false,
        type: "boolean",
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => params.row.externalId !== null,
        renderCell: ({ value }) => (value ? <CheckIcon /> : <CloseIcon />),
      },
    ],
    [
      primaryClassifications,
      secondaryClassifications,
      timesheet.customers,
      rowModesModel,
    ]
  );

  const handleProcessRowUpdateError = (error: Error) => {
    setSnackbar({
      children: error.message,
      severity: "error",
    });
  };

  const processRowUpdate = async (
    newRow: GridRowModel<LedgerTransactionApiRow>,
    oldRow: GridRowModel<LedgerTransactionApiRow>
  ) => {
    const { isNew, id, ...newRowData } = newRow;
    let updatedRow: LedgerTransactionApiRow;

    const getResponseBody = async (response: Response) => {
      if (response.status === 401) {
        auth.signOut();
        throw new Error("Unauthenticated");
      }

      const body = await response.json();

      if (response.status === 400) {
        const codedMessage: string = body.message.filter(
          (message: string) =>
            message.endsWith("should not be empty") ||
            message.endsWith("must be a valid enum value")
        )[0];

        if (codedMessage) {
          let field: string;
          const message = "não pode ser vazio";
          if (codedMessage.startsWith("date")) {
            field = '"Data"';
          } else if (codedMessage.startsWith("amount")) {
            field = '"Valor"';
          } else if (codedMessage.startsWith("description")) {
            field = '"Identificação"';
          } else if (codedMessage.startsWith("type")) {
            field = '"Tipo"';
          } else if (codedMessage.startsWith("primaryClassification")) {
            field = '"Classificação"';
          } else if (codedMessage.startsWith("secondaryClassification")) {
            field = '"Descrição"';
          } else {
            field = codedMessage.split(" ")[0];
          }

          throw new Error(`O campo ${field} ${message}`);
        }
      }

      if (![200, 201].includes(response.status)) {
        console.warn(`Failed to insert/modify timesheet: ${response.status}`);
        throw new Error(
          "Alguma coisa inesperada aconteceu, tente novamente mais tarde."
        );
      }

      return body;
    };

    if (isNew) {
      const body = JSON.stringify({
        ...newRowData,
        date: !newRowData.date
          ? newRowData.date
          : typeof newRowData.date === "string"
          ? newRowData.date
          : newRowData.date.toISOString().split("T")[0],
      });
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/ledger/${ledgerId}/transaction`,
        {
          body,
          headers: {
            Authorization: `Bearer ${auth.encodedToken!}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );

      const responseBody = await getResponseBody(response);

      updatedRow = { ...responseBody, isNew: false };
      console.log({ updatedRow });
      setSnackbar({
        children: "Transação criada com sucesso!",
        severity: "success",
      });
    } else {
      const compareEntries = (a: [string, any], b: [string, any]) =>
        a[0] === b[0] && a[1] === b[1];

      const getComparableRowEntries = (row: LedgerTransactionApiRow) => {
        const rowData = R.omit(row, ["isNew", "id", "externalId"]);

        const comparableRow: Partial<LedgerTransactionApiRow> = rowData;

        return Object.entries(comparableRow);
      };

      let newAmount = newRow.amount;

      const regex = /\(?R\$\s(\d{1,3}(\.\d{3})*,\d{2})\)?/;
      const result = regex.exec(newAmount);

      if (result?.[1]) {
        newAmount = (
          parseFloat(result?.[1].replace(".", "").replace(",", ".")) *
          (newRow.type === "DEBIT" ? -100 : 100)
        ).toString();
      }

      newRow.amount = newAmount;

      console.log(
        getComparableRowEntries(newRow),
        getComparableRowEntries(oldRow)
      );
      const diff = R.differenceWith(
        getComparableRowEntries(newRow),
        getComparableRowEntries(oldRow),
        compareEntries
      );

      if (diff.length > 0) {
        const body = JSON.stringify(R.fromPairs(diff));

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/ledger/${ledgerId}/transaction/${id}`,
          {
            body,
            headers: {
              Authorization: `Bearer ${auth.encodedToken!}`,
              "Content-Type": "application/json",
            },
            method: "PATCH",
          }
        );

        const responseBody = (await getResponseBody(response)).data;

        updatedRow = { ...newRow, ...responseBody, isNew: false };
        setSnackbar({
          children: "Transação atualizada com sucesso!",
          severity: "success",
        });
      } else {
        updatedRow = oldRow;
      }
    }

    setRows((rows) =>
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );

    return updatedRow;
  };

  return (
    <Container maxWidth="xl">
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link underline="hover" color="inherit" href="/finances/ledger">
          Movimentações Financeiras
        </Link>
        <Typography color="text.primary">
          {loadingPrimaryClassifications || loadingTransactions
            ? "Carregando..."
            : `${transactions?.ledger.name} - ${transactions?.ledger.bankAccount.financialInstitution} - Conta ${transactions?.ledger.bankAccount.accountNumber}`}
        </Typography>
      </Breadcrumbs>
      <Stack marginTop={2}>
        {loadingPrimaryClassifications || loadingTransactions ? (
          <>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              animation="wave"
            />
          </>
        ) : (
          <>
            {transactions && (
              <Accordion defaultExpanded={true} sx={{ marginTop: 2 }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <InfoIcon />
                    <Typography variant="h6">Detalhes</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={2}>
                    <Typography>
                      <strong>Origem:</strong>{" "}
                      {`${transactions.ledger.bankAccount.financialInstitution} - Conta ${transactions.ledger.bankAccount.accountNumber}`}
                    </Typography>
                    <Typography>
                      <strong>Moeda:</strong> {transactions.ledger.currency}
                    </Typography>
                    <Divider />
                    <Typography>
                      <strong>Saldo Inicial:</strong>{" "}
                      {
                        toCurrencyString(transactions.summary.startBalance)
                          .formattedValue
                      }
                    </Typography>
                    <Typography>
                      <strong>Saldo Movimentações:</strong>{" "}
                      {
                        toCurrencyString(
                          transactions.summary.transactionsBalance
                        ).formattedValue
                      }
                    </Typography>
                    <Typography>
                      <strong>Saldo Final:</strong>{" "}
                      {
                        toCurrencyString(transactions.summary.endBalance)
                          .formattedValue
                      }
                    </Typography>
                  </Stack>
                  {/* <Stack
                    padding={3}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FinanceStatistic
                      name="Saldo Inicial"
                      type="currency"
                      value={transactions.summary.startBalance}
                    />
                    <ArrowForward />
                    <FinanceStatistic
                      name="Saldo Movimentações"
                      type="currency"
                      value={transactions.summary.transactionsBalance}
                    />
                    <ArrowForward />
                    <FinanceStatistic
                      name="Saldo Final"
                      type="currency"
                      value={transactions.summary.endBalance}
                    />
                  </Stack> */}
                </AccordionDetails>
              </Accordion>
            )}

            {transactions && (
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <AttachMoney />
                    <Typography variant="h6">Movimentações</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <DataGrid
                    autoHeight
                    rows={rows}
                    isCellEditable={isCellEditable}
                    columns={columns}
                    components={{
                      NoRowsOverlay: () => (
                        <Stack
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          Nenhuma Transação existente
                        </Stack>
                      ),
                      NoResultsOverlay: () => (
                        <Stack
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          Nenhuma Transação encontrada
                        </Stack>
                      ),
                      Toolbar: LedgerTransactionsToolbar,
                    }}
                    componentsProps={{
                      toolbar: {
                        onInsertRow: startInsertingRow,
                      },
                    }}
                    localeText={{
                      // Rows selected footer text
                      footerRowSelected: (count) =>
                        count !== 1
                          ? `${count.toLocaleString()} linhas selecionadas`
                          : `${count.toLocaleString()} linha selecionada`,
                      footerTotalRows: "Total páginas: ",
                      footerTotalVisibleRows: (visibleCount, totalCount) =>
                        `${visibleCount.toString()} de ${totalCount.toLocaleString()} linhas`,
                      MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                          `Linhas ${from} até ${to} (total: ${count})`,
                      },
                    }}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={onRowModesModelChange}
                    onRowEditStart={onRowEditStart}
                    onRowEditStop={onRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    pagination
                    paginationMode="server"
                    page={page}
                    pageSize={pageSize}
                    rowCount={transactions.count ?? 0}
                    onPageChange={setPage}
                    rowsPerPageOptions={[100]}
                    onPageSizeChange={setPageSize}
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}
      </Stack>
      {/* {transactions?.summary && (
        <Paper
          sx={{
            mt: 2,
            mb: 2,
            p: 2,
          }}
        >
          <Typography variant="h6" textAlign="center">
            Balanços
          </Typography>

          <Typography sx={{ mt: 2, textAlign: "center" }}>
            Balanço Inicial: {transactions?.summary.startBalance}
            <br />
            Balanço das Transações: {transactions?.summary.transactionsBalance}
            <br />
            Balanço Final: {transactions?.summary.endBalance}
          </Typography>
        </Paper>
      )} */}
      {rowToDelete && (
        <Dialog
          open
          onClose={handleRemoveDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Você tem certeza que deseja remover esta Transação?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Você está prestes a remover a Transação "${rowToDelete.description}".`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemoveDialogClose} autoFocus>
              Cancelar
            </Button>
            <LoadingButton
              loading={isDeleting}
              color="error"
              onClick={handleRemoveDialogAction}
            >
              Remover
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
    </Container>
  );
}
