import React from "react";

export type UseBooleanControlFn = {
  close: () => void;
  open: () => void;
  toggle: () => void;
};

export type UseBooleanReturnType = [boolean, UseBooleanControlFn];

export default function useBoolean(
  defaultValue: boolean = false
): UseBooleanReturnType {
  const [isOpen, setIsOpen] = React.useState(defaultValue);

  const toggle = () => {
    setIsOpen((previousValue) => !previousValue);
  };

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return [isOpen, { close, open, toggle }];
}
