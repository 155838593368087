import { TextField, TextFieldProps } from "@mui/material";
import React, { ChangeEventHandler } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  prefix: SupportedCurrencies;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type SupportedCurrencies = "BRL" | "USD";

export const CurrencySymbols: Record<SupportedCurrencies, string> = {
  BRL: "R$ ",
  USD: "$",
};

export const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, prefix, ...otherProps } = props;

  // RTL support
  // https://github.com/s-yadav/react-number-format/issues/197#issuecomment-921469056
  return (
    <NumericFormat
      {...otherProps}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { name: otherProps.name, value: values.value } });
      }}
      allowedDecimalSeparators={[",", "."]}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={true}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      // prefix={CurrencySymbols[prefix]}
    />
  );
});

type CurrencyTextFieldProps = TextFieldProps & {
  currency?: string;
};

const CurrencyTextField: React.FC<CurrencyTextFieldProps> = (props) => {
  const { currency = "BRL", ...otherProps } = props;
  const [value, setValue] = React.useState<string>(
    typeof props.value === "string" ? props.value : ""
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    props.onChange?.(event);
  };

  // console.log("CurrencyTextField", {
  //   id: "PrevJaneiro-2fc0b3f9-57ad-4156-9b9b-e60b2ea6319e",
  //   currencyValue: value,
  //   propsValue: props.value,
  // });
  return (
    <TextField
      {...otherProps}
      value={value}
      onChange={handleChange}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        inputProps: {
          style: { fontSize: "0.8125rem" },
          prefix: currency,
        },
      }}
      autoFocus
    />
  );
};

export default CurrencyTextField;
