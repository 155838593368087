import React, { useCallback, useState } from "react";

import { Close as CloseIcon } from "@mui/icons-material";

import {
  Alert,
  AlertProps,
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FileUpload from "../../FileUpload";
import { LedgerApiRow } from "../../../routes/Finances/LedgerPage";
import { useFetch } from "use-http";
import axios from "axios";
import { useAuth } from "../../../contexts/AuthContext";
import { LedgerTransactionApiRow } from "../../../routes/Finances/LedgerTransactionsPage";
import { toCurrencyString } from "../../../utils/finance";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CurrencyTextField, {
  CurrencySymbols,
  NumericFormatCustom,
} from "../../CurrencyTextField";
import { NumericFormat } from "react-number-format";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type Snackbar = Pick<AlertProps, "children" | "severity">;
type ImportLedgerTransactionApiRow = Omit<LedgerTransactionApiRow, "id">;
type ImportLedgerApiRow = Omit<LedgerApiRow, "id" | "transactions"> & {
  transactions?: ImportLedgerTransactionApiRow[];
};

class ErrorBoundary extends React.Component<
  React.PropsWithChildren,
  { errorMessage: string | undefined }
> {
  constructor(props: any) {
    super(props);
    this.state = { errorMessage: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { errorMessage: error.message };
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => this.setState({ errorMessage: undefined })}
          autoHideDuration={6000}
        >
          <Alert
            severity="error"
            onClose={() => this.setState({ errorMessage: undefined })}
          >
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
      );
    }

    return this.props.children;
  }
}

export default function ImportStatementModalStepper(props: {
  open: boolean;
  onClose: (success: boolean) => void;
}) {
  const steps = ["Enviar Arquivo", "Revisar"];
  const [snackbar, setSnackbar] = React.useState<Snackbar | null>(null);
  const [hasStartBalance, setHasStartBalance] = useState<boolean | undefined>();
  const [startBalance, setStartBalance] = useState<string>("");

  const auth = useAuth();
  const { post: createLedger } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/ledger`,
    {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
      method: "post",
    }
  );

  const [ledger, setLedger] = React.useState<ImportLedgerApiRow | undefined>();
  const [activeStep, setActiveStep] = React.useState(0);
  const [progress, setProgress] = React.useState<number | undefined>();

  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleUpload = async (file: File) => {
    console.log(`uploading file "${file.name}" of size ${file.size}`);
    try {
      const data = new FormData();
      data.append("file", file);

      const response = await axios.post<ImportLedgerApiRow>(
        `${process.env.REACT_APP_API_BASE_URL}/ledger/import`,
        data,
        {
          headers: {
            Authorization: `Bearer ${auth.encodedToken!}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.progress ??
                progressEvent.loaded / (progressEvent.total ?? 1)) * 100
            );
            setProgress(progress);
          },
        }
      );

      setHasStartBalance(response.data.startBalance ? true : false);
      setStartBalance(response.data.startBalance);

      console.log("received imported ledger!");
      setLedger(response.data);
    } catch (error) {
      // TODO: toast?
      console.error(error);
    }
  };

  const handleFinish = useCallback(async () => {
    try {
      if (
        !hasStartBalance &&
        (!startBalance || isNaN(parseFloat(startBalance)))
      ) {
        setSnackbar({ severity: "error", children: "Saldo Inicial inválido" });
        return;
      }
      await createLedger(
        !hasStartBalance
          ? {
              ...ledger,
              startBalance: (parseFloat(startBalance) * 100).toString(),
            }
          : ledger
      );
      props.onClose(true);
    } catch (error) {
      console.error(error);
    }
  }, [createLedger, ledger, props.onClose, startBalance]);

  return (
    <React.Fragment>
      <ErrorBoundary>
        <Modal onClose={props.onClose} open={props.open}>
          <Box sx={{ ...style, width: 500 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" component="h2" lineHeight={1.8}>
                Importar Extrato
              </Typography>
              <IconButton onClick={() => props.onClose(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>

            <Stepper activeStep={activeStep} sx={{ mt: 2, mb: 3 }}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <Stack>
                <Typography>
                  Para importar o extrato bancário, clique no botão abaixo e
                  selecione um arquivo OFX.
                </Typography>
                <FileUpload
                  progress={progress}
                  upload={handleUpload}
                  accept=".ofx"
                />
              </Stack>
            )}
            {activeStep === steps.length - 1 && (
              <Stack>
                <Typography>
                  Por favor, revise as informações abaixo e clique em
                  "Finalizar" para importar o extrato.
                </Typography>
                <ul>
                  <li>{`Banco: ${ledger?.bankAccount.financialInstitution}`}</li>
                  <li>{`Conta Bancária: ${ledger?.bankAccount.accountNumber}`}</li>
                  <li>{`Período: ${ledger?.name}`}</li>
                  <li>{`Moeda: ${ledger?.currency}`}</li>
                  {hasStartBalance ? (
                    <li>
                      {`Saldo Inicial: ${
                        toCurrencyString(startBalance).formattedValue
                      }`}{" "}
                      <Tooltip
                        title="Valor originado do extrato, não pode ser editado."
                        placement="bottom"
                        arrow
                      >
                        <HelpOutlineOutlinedIcon sx={{ fontSize: "1rem" }} />
                      </Tooltip>
                    </li>
                  ) : (
                    <>
                      <p>
                        <b>{`Valor do Saldo Inicial não identificado, favor informar abaixo:`}</b>
                      </p>
                      <TextField
                        variant="standard"
                        label="Saldo Inicial"
                        value={startBalance}
                        onChange={(event) =>
                          setStartBalance(event.target.value)
                        }
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                          inputProps: {
                            prefix: CurrencySymbols.BRL,
                          },
                        }}
                      />
                    </>
                  )}
                </ul>
              </Stack>
            )}
            <Stack
              direction="row"
              justifyContent={activeStep > 1 ? "space-between" : "flex-end"}
              mt={3}
            >
              {activeStep > 0 && (
                <Button onClick={handlePreviousStep}>Anterior</Button>
              )}
              {activeStep === steps.length - 1 ? (
                <Button onClick={handleFinish}>Finalizar</Button>
              ) : (
                <Tooltip placement="top" title="Envie o arquivo">
                  <span>
                    <Button
                      disabled={progress !== 100}
                      onClick={handleNextStep}
                    >
                      Próximo
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Stack>
          </Box>
        </Modal>
        <Snackbar
          open={!!snackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setSnackbar(null)}
          autoHideDuration={6000}
        >
          <Alert
            {...snackbar}
            severity="error"
            onClose={() => setSnackbar(null)}
          />
        </Snackbar>
      </ErrorBoundary>
    </React.Fragment>
  );
}
