import React, { FormEvent, ReactElement } from "react";
import * as R from "remeda";

import styled from "@emotion/styled";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderEditCellParams,
  GridRowEditStartParams,
  GridRowEditStopParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from "@mui/x-data-grid";
import { IMaskInput } from "react-imask";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  AlertProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Snackbar,
  Tooltip,
  Typography,
  InputBaseComponentProps,
  Container,
  Paper,
} from "@mui/material";
import { AuthRole, useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export interface CustomerRow {
  id: number;
  name: string;
  document: string;
  customerType: "Pessoa Física" | "Pessoa Jurídica";
  addressStreet: string;
  addressNumber?: string;
  addressComplement?: string;
  addressCity: string;
  addressStateProvince: string;
  addressCountry: string;
  addressZipcode: string;
  isNew: boolean;
}

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// `;

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

interface MaskedInputEditCellProps extends GridRenderEditCellParams {
  mask: string;
}
interface MaskedInputProps extends InputBaseComponentProps {
  mask: string;
  onChange: (event: FormEvent) => void;
}

const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  function MaskedInputEditCell(props, ref) {
    const { mask, onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={(_value, _mask, event: any) => {
          onChange(event);
        }}
      />
    );
  }
);

function MaskedInputEditCell(props: MaskedInputEditCellProps) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue as string });
  };

  // console.log({ value });

  return (
    <Input
      inputComponent={MaskedInput as any}
      disableUnderline
      inputProps={{ mask: props.mask }}
      value={value}
      onChange={handleValueChange}
      sx={{
        "& > :not(style)": {
          m: 1,
        },
      }}
    />
  );
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = "-";
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        name: "",
        customerType: "Pessoa Física",
        document: "",
        addressStreet: "",
        addressNumber: "",
        addressComplement: "",
        addressCity: "",
        addressStateProvince: "",
        addressCountry: "",
        addressZipcode: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Inserir Cliente
      </Button>
    </GridToolbarContainer>
  );
}

export default function ListCustomers() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [rows, setRows] = React.useState<CustomerRow[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState<
    CustomerRow | undefined
  >();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  React.useEffect(() => {
    if (auth.decodedToken!.role === AuthRole.USER) {
      // TODO: navigate to 403 or 404 page
      navigate("/timesheet");
      return;
    }

    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/customers`);
    url.searchParams.append("page", page.toString());
    url.searchParams.append("size", pageSize.toString());

    fetch(url, {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          auth.signOut();
        }

        return response;
      })
      .then((data) => data.json())
      .then((data) => {
        setRowCount(data.pagination.totalCount);
        setRows(data.items.map((d: CustomerRow) => ({ ...d, isNew: false })));
      })
      .catch((error: Error) => {
        console.warn(error.message);
        setSnackbar({
          children: "Falha ao listar clientes",
          severity: "error",
        });
      });
  }, [page, pageSize]);

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleEditClick = (id: GridRowId) => () => {
    console.log("editing");
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRowToDelete(rows.find((row) => row.id === id));
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    // TODO: implement edit request
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const columns: GridColumns<CustomerRow> = [
    {
      field: "id",
      headerName: "ID Cliente",
      width: 150,
      editable: false,
    },
    {
      field: "name",
      headerName: "Nome / Razão Social",
      flex: 1,
      minWidth: 300,
      editable: true,
    },
    {
      field: "customerType",
      type: "singleSelect",
      valueOptions: ["Pessoa Física", "Pessoa Jurídica"],
      headerName: "Tipo Cliente",
      flex: 0.8,
      minWidth: 150,
      editable: true,
    },
    {
      field: "document",
      headerName: "CPF / CNPJ",
      width: 180,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams<any, CustomerRow>) => {
        return (
          <MaskedInputEditCell
            {...params}
            mask={
              params.row.customerType === "Pessoa Física"
                ? "000.000.000-00"
                : "00.000.000/0000-00"
            }
          />
        );
      },
    },
    {
      field: "addressStreet",
      headerName: "Endereço",
      minWidth: 300,
      editable: true,
    },
    {
      field: "addressNumber",
      headerName: "Número",
      minWidth: 100,
      editable: true,
    },
    {
      field: "addressComplement",
      headerName: "Complemento",
      minWidth: 130,
      editable: true,
    },
    {
      field: "addressCity",
      headerName: "Cidade",
      minWidth: 130,
      editable: true,
    },
    {
      field: "addressStateProvince",
      headerName: "Estado",
      minWidth: 130,
      editable: true,
    },
    {
      field: "addressCountry",
      headerName: "País",
      minWidth: 130,
      editable: true,
    },
    {
      field: "addressZipcode",
      headerName: "CEP",
      minWidth: 130,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams<any, CustomerRow>) => {
        return <MaskedInputEditCell {...params} mask={"00000-000"} />;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 80,
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Salvar">
                  <SaveIcon />
                </Tooltip>
              }
              label="Salvar"
              onClick={handleSaveClick(params.id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancelar">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancelar"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Editar">
                <EditIcon />
              </Tooltip>
            }
            label="Editar"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Deletar">
                <DeleteIcon />
              </Tooltip>
            }
            label="Deletar"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleRowModesModelChange = (newModel: GridRowModesModel) => {
    setRowModesModel(newModel);
  };

  const handleRowEditStart = (
    _params: GridRowEditStartParams,
    event: MuiEvent
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (
    _params: GridRowEditStopParams<CustomerRow>,
    event: MuiEvent
  ) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (
    newRow: GridRowModel<CustomerRow>,
    oldRow: GridRowModel<CustomerRow>
  ) => {
    const { isNew, ...newRowData } = newRow;
    let updatedRow: CustomerRow;

    const getResponseBody = async (response: Response) => {
      if (response.status === 401) {
        auth.signOut();
        throw new Error("Unauthenticated");
      }

      const body = await response.json();

      if (response.status === 400) {
        const codedMessage: string = body.message[0];

        if (codedMessage.endsWith("should not be empty")) {
          let field: string;
          const message = "não pode ser vazio";
          if (codedMessage.startsWith("name")) {
            field = '"Nome / Razão Social"';
          } else if (codedMessage.startsWith("document")) {
            field = '"CPF / CNPJ"';
          } else if (codedMessage.startsWith("addressStreet")) {
            field = '"Endereço"';
          } else if (codedMessage.startsWith("addressCity")) {
            field = '"Cidade"';
          } else if (codedMessage.startsWith("addressStateProvince")) {
            field = '"Estado"';
          } else if (codedMessage.startsWith("addressCountry")) {
            field = '"País"';
          } else if (codedMessage.startsWith("addressZipcode")) {
            field = '"CEP"';
          } else {
            field = codedMessage.split(" ")[0];
          }

          throw new Error(`O campo ${field} ${message}`);
        }
      }

      if (![200, 201].includes(response.status)) {
        console.warn(`Failed to insert/modify customer: ${response.status}`);
        throw new Error(
          "Alguma coisa inesperada aconteceu, tente novamente mais tarde."
        );
      }

      return body;
    };

    if (isNew) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/customers`,
        {
          body: JSON.stringify(R.omit(newRowData, ["id"])),
          headers: {
            Authorization: `Bearer ${auth.encodedToken!}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );

      const responseBody = await getResponseBody(response);

      console.log({ responseBody });
      updatedRow = { ...responseBody, isNew: false };
      setSnackbar({
        children: "Cliente inserido com sucesso!",
        severity: "success",
      });
    } else {
      const compareEntries = (a: [string, any], b: [string, any]) =>
        a[0] === b[0] && a[1] === b[1];
      const diff = R.differenceWith(
        Object.entries(newRow),
        Object.entries(oldRow),
        compareEntries
      );

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/customers/${newRow.id}`,
        {
          body: JSON.stringify(R.fromPairs(diff)),
          headers: {
            Authorization: `Bearer ${auth.encodedToken!}`,
            "Content-Type": "application/json",
          },
          method: "PATCH",
        }
      );

      await getResponseBody(response);

      updatedRow = { ...newRow, isNew: false };
      setSnackbar({
        children: "Cliente atualizado com sucesso!",
        severity: "success",
      });
    }

    setRows(
      rows.map((row) => {
        console.log(row.id, newRow.id);
        return row.id === newRow.id ? updatedRow : row;
      })
    );
    return updatedRow;
  };

  const handleProcessRowUpdateError = (error: Error) => {
    setSnackbar({ children: error.message, severity: "error" });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleRemoveDialogClose = () => {
    setRowToDelete(undefined);
  };

  const handleRemoveDialogAction = React.useCallback(() => {
    if (!rowToDelete) {
      return;
    }

    const url = new URL(
      `${process.env.REACT_APP_API_BASE_URL}/customers/${rowToDelete.id}`
    );

    fetch(url, {
      headers: {
        Authorization: `Bearer ${auth.encodedToken!}`,
      },
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 401) {
          auth.signOut();
        }

        return response;
      })
      .then((data) => {
        if (data.status !== 200) {
          throw new Error("Failed Delete");
        }

        setRows(rows.filter((row) => row.id !== rowToDelete.id));
        setSnackbar({
          children: "Cliente removido com sucesso",
          severity: "success",
        });
      })
      .catch((error: Error) => {
        console.warn(error.message);
        setSnackbar({
          children: "Falha ao remover cliente",
          severity: "error",
        });
      })
      .finally(() => {
        setRowToDelete(undefined);
      });
  }, [rowToDelete]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h6" gutterBottom>
        Lista de Clientes
      </Typography>
      <Paper>
        <DataGrid
          autoHeight
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          localeText={{
            // Rows selected footer text
            footerRowSelected: (count) =>
              count !== 1
                ? `${count.toLocaleString()} linhas selecionadas`
                : `${count.toLocaleString()} linha selecionada`,
            footerTotalRows: "Total páginas: ",
            footerTotalVisibleRows: (visibleCount, totalCount) =>
              `${visibleCount.toString()} de ${totalCount.toLocaleString()} linhas`,
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `Linhas ${from} até ${to} (total: ${count})`,
            },
          }}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          pagination
          paginationMode="server"
          page={page}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[]}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          experimentalFeatures={{ newEditingApi: true }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Paper>
      {!!rowToDelete && (
        <Dialog
          open
          onClose={handleRemoveDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Você tem certeza que deseja remover este cliente?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Você está prestes a remover o cliente ${rowToDelete.name} (ID: ${rowToDelete.id})`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemoveDialogClose} autoFocus>
              Cancelar
            </Button>
            <Button color="error" onClick={handleRemoveDialogAction}>
              Remover
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
}
